import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import { updateSearch } from 'actions/search';
import withUser from 'components/withUser';
import SignInMutation from 'queries/mutations/SignInMutation.gql';
import SurveyVisitEmailMutation from 'queries/mutations/SurveyVisitEmailMutation.gql';
import CurrentUserQuery from 'queries/CurrentUserQuery.gql';

class SignInForm extends Component {
  state = {
    email: this.props.email ? this.props.email : '',
    password: '',
    loginError: false,
    alreadyAUserError: this.props.email,
  };

  static contextTypes = {
    client: PropTypes.any,
  };

  static propTypes = {
    clientLoginEmailMutation: PropTypes.func.isRequired,
    location: PropTypes.shape({ pathname: PropTypes.string }),
    history: PropTypes.object.isRequired,
    onSuccess: PropTypes.func.isRequired,
    updateSearch: PropTypes.func.isRequired,
    login: PropTypes.func.isRequired,
    redirectTo: PropTypes.string,
  };

  static defaultProps = {
    location: {},
    redirectTo: undefined,
  };

  emailRef = null;

  passwordRef = null;

  setEmailRef = (element) => {
    this.emailRef = element;
  };

  setPasswordRef = (element) => {
    this.passwordRef = element;
  };

  sendClientLoginNotificationEmail = (campaignId) =>
    this.props.clientLoginEmailMutation({ variables: { campaignId } });

  onChangeField = (evt) =>
    this.setState({
      [evt.target.name]: evt.target.value,
    });

  onSubmit = (evt) => {
    this.props
      .signInMutation({
        variables: {
          email: this.emailRef.value,
          password: this.passwordRef.value,
        },
        refetchQueries: [
          {
            query: CurrentUserQuery,
          },
        ],
      })
      .then(({ data }) => {
        this.context.client.resetStore(); //Kickoff the Apollo store reset now that the user has logged in. But don't wait for it, because there's a bug somewhere that this isn't resolving
        this.props.login(data.signIn.currentUser);
        if (data.signIn?.currentUser?.cityOfInterest) {
          this.props.updateSearch({
            numEmployees:
              data.signIn.currentUser.latestCampaign.currentActiveRequirements
                ?.numEmployees,
            neighborhoods:
              data.signIn.currentUser.latestCampaign.currentActiveRequirements?.neighborhoods?.map(
                ({ name }) => name
              ) || [],
            cityName: data.signIn.currentUser.cityOfInterest,
          });
        }
        if (!data.signIn.currentUser) {
          // Request was successful but login failed
          // Credentials were invalid
          this.setState({ loginError: true });
        } else {
          this.setState({
            loginError: false,
            alreadyAUserError: false,
            email: '',
            password: '',
          });
        }

        // if broker just logged in, redirect to campaigns
        if (data.signIn.currentUser.isBroker) {
          this.props.history.push('/tourbook/campaigns');
        } else {
          this.sendClientLoginNotificationEmail(
            data.signIn.currentUser.latestCampaign.id
          );
          if (this.props.location.pathname === '/sign-in') {
            if (this.props.redirectTo) window.location = this.props.redirectTo;
            else this.props.history.push('/');
          }
        }

        if (this.props.onSuccess) this.props.onSuccess(data.signIn.currentUser);
      })
      .catch((err) => {
        // Error in making the request
        console.error('signIn GraphQL Mutation error', err);
      });
    evt.persist();
    evt.preventDefault();
    return false;
  };

  render() {
    return (
      <form className="sign-in" onSubmit={this.onSubmit}>
        <input
          type="email"
          name="email"
          placeholder="Email"
          required
          ref={this.setEmailRef}
          value={this.state.email}
          onChange={this.onChangeField}
          className={
            this.state.loginError || this.state.alreadyAUserError ? 'error' : ''
          }
        />
        <input
          type="password"
          name="password"
          placeholder="Password"
          required
          ref={this.setPasswordRef}
          onChange={this.onChangeField}
          className={
            this.state.loginError || this.state.alreadyAUserError ? 'error' : ''
          }
        />
        {this.state.loginError && (
          <p className="form-error">
            We could not find that email/password combination.
            <br />
            Please try typing in your login information again.
          </p>
        )}
        {this.state.alreadyAUserError && (
          <p className="form-error">
            It looks like you already have an account. Enter your password or
            click &quot;Forget your password?&quot; to reset.
          </p>
        )}
        <input type="submit" value="Sign In" />
        <Link
          style={{ float: 'right', marginTop: 8, fontSize: 14 }}
          to="/forgot-password"
          onClick={this.props.onSuccess}
        >
          Forget your password?
        </Link>
      </form>
    );
  }
}

export default compose(
  withRouter,
  withUser,
  connect(null, {
    updateSearch,
  }),
  graphql(SurveyVisitEmailMutation, { name: 'clientLoginEmailMutation' }),
  graphql(SignInMutation, { name: 'signInMutation' })
)(SignInForm);
