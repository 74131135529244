import LoadCampaignSettingsQuery from 'queries/LoadCampaignSettingsQuery.gql';
import AddListingsMutation from 'queries/mutations/AddListingsMutation.gql';
import RemoveListingsMutation from 'queries/mutations/RemoveListingsMutation.gql';
import { autoHideToast } from './toast';

const initialState = {
  activeCampaign: null,
  expandedListingId: null,
  selectedListingIds: [],
  isDrawerOpen: false,
  isMapOpen: true,
  selectedAdditionalCampaigns: [],
};

export const selectActiveCampaign = (payload) => ({
  type: 'TOURBOOK/SELECT_ACTIVE_CAMPAIGN',
  payload,
});
export const toggleAdditionalCampaign = (payload) => ({
  type: 'TOURBOOK/TOGGLE_ADDITIONAL_CAMPAIGN',
  payload,
});
export const expandListing = (listing) => ({
  type: 'TOURBOOK/EXPAND_LISTING',
  payload: { id: listing.id },
});
export const selectListing = (listingId) => ({
  type: 'TOURBOOK/SELECT_LISTING',
  payload: { id: listingId },
});
export const clearSelectedListings = () => ({
  type: 'TOURBOOK/CLEAR_SELECTED_LISTINGS',
});
export const toggleDrawer = () => ({ type: 'TOURBOOK/TOGGLE_DRAWER' });
export const toggleMap = () => ({ type: 'TOURBOOK/TOGGLE_MAP' });

export const loadCampaignSearchSettings = (campaign, apollo) => (dispatch) => {
  const variables = {};
  if (campaign.uuid) {
    variables.uuid = campaign.uuid;
  } else {
    variables.id = campaign.id;
  }
  return apollo
    .query({
      query: LoadCampaignSettingsQuery,
      variables,
    })
    .then((res) => {
      dispatch({
        type: 'TOURBOOK/SELECT_ACTIVE_CAMPAIGN',
        payload: { ...campaign, ...res.data.campaign },
      });
      return res;
    })
    .catch(() => dispatch(autoHideToast('Error loading campaign.', 5000)));
};

export const addListings =
  (listingIds, { campaigns, surveys, tours } = {}, apollo) =>
  (dispatch) => {
    const listingsLabel = `${listingIds.length} listing${
      listingIds.length !== 1 ? 's' : ''
    }`;
    let addedTo = '';
    if (campaigns)
      addedTo +=
        campaigns.map((c) => `${c.displayName}'s campaign`).join(', ') + ' ';
    if (surveys) addedTo += surveys.map((s) => s.name).join(', ') + ' ';
    if (tours) addedTo += tours.map((t) => t.name).join(', ') + ' ';

    return apollo
      .mutate({
        mutation: AddListingsMutation,
        variables: {
          listingIds,
          campaignIds: campaigns
            ? campaigns.map((campaign) => campaign.id)
            : [],
          surveyIds: surveys ? surveys.map((survey) => survey.id) : [],
          tourIds: tours ? tours.map((tour) => tour.id) : [],
        },
      })
      .then(() => dispatch(clearSelectedListings()))
      .then(() =>
        dispatch(autoHideToast(`Added ${listingsLabel} to ${addedTo}.`))
      )
      .catch((e) => {
        dispatch(
          autoHideToast(
            `Error adding ${listingsLabel}. Please try again later!`,
            5000
          )
        );
        console.error(e);
      });
  };

/* eslint-disable no-alert */
export const removeListings =
  (
    { campaignListingIds, surveyListingIds, surveyName },
    force = false,
    apollo
  ) =>
  (dispatch) => {
    const confirmMessage = surveyListingIds?.length
      ? `Are you sure you want to remove these listings from '${
          surveyName || 'this Survey'
        }'?`
      : 'Are you sure you want to remove these listings from this Campaign?';
    const confirmForceMessage =
      'These listings appear in one or more Surveys or Tours. If you delete them, they will be deleted from those Surveys or Tours. Are you sure you want to delete?';

    if (force || confirm(confirmMessage)) {
      return apollo
        .mutate({
          mutation: RemoveListingsMutation,
          variables: { campaignListingIds, surveyListingIds, force },
        })
        .then(() => dispatch(autoHideToast('Listings removed successfully.')))
        .catch((e) => {
          if (
            e.message.match(/Call with `force`/) &&
            confirm(confirmForceMessage)
          ) {
            return dispatch(
              removeListings(
                { campaignListingIds, surveyListingIds },
                true,
                apollo
              )
            );
          }
          dispatch(
            autoHideToast(
              'Error removing listings from campaign. Please try again later!',
              5000
            )
          );
          console.error(e);
          return null;
        });
    }
    return null;
  };
/* eslint-enable no-alert */

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'TOURBOOK/SELECT_ACTIVE_CAMPAIGN':
      return {
        ...state,
        activeCampaign: action.payload,
        selectedListingIds: [],
      };
    case 'TOURBOOK/TOGGLE_ADDITIONAL_CAMPAIGN': {
      const toggledCampaign = action.payload;
      const selectedCampaigns = state.selectedAdditionalCampaigns;
      const isCampaignCurrentlySelected = !!selectedCampaigns.find(
        (campaign) => campaign.id === toggledCampaign.id
      );

      if (isCampaignCurrentlySelected) {
        return {
          ...state,
          selectedAdditionalCampaigns: selectedCampaigns.filter(
            (campaign) => campaign.id !== toggledCampaign.id
          ),
        };
      }
      return {
        ...state,
        selectedAdditionalCampaigns: selectedCampaigns.concat([
          toggledCampaign,
        ]),
      };
    }
    case 'TOURBOOK/EXPAND_LISTING':
      return {
        ...state,
        expandedListingId: action.payload.id,
        isDrawerOpen:
          action.payload.id !== state.expandedListingId
            ? true
            : !state.isDrawerOpen,
      };
    case 'TOURBOOK/SELECT_LISTING':
      return {
        ...state,
        selectedListingIds:
          state.selectedListingIds.indexOf(action.payload.id) !== -1
            ? state.selectedListingIds.filter((id) => id !== action.payload.id)
            : state.selectedListingIds.concat([action.payload.id]),
      };
    case 'TOURBOOK/CLEAR_SELECTED_LISTINGS':
      return {
        ...state,
        selectedListingIds: [],
        selectedAdditionalCampaigns: [],
      };
    case 'TOURBOOK/TOGGLE_DRAWER':
      return {
        ...state,
        isDrawerOpen: !state.isDrawerOpen,
      };
    case 'TOURBOOK/TOGGLE_MAP':
      return {
        ...state,
        isMapOpen: !state.isMapOpen,
      };
    default:
      return state;
  }
}
