import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import H2 from 'components/deprecated/legacyElements/H2';
import Button from 'components/componentLibrary/button/Text';

export default class ErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  state = { error: null, errorInfo: null };

  componentDidUpdate(prev) {
    if (!isEqual(prev.children, this.props.children)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ error: null, errorInfo: null });
    }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo,
    });
  }

  render() {
    if (this.state.errorInfo || this.state.error) {
      // Error path
      return (
        <div className="inset">
          <H2>Something went wrong.</H2>
          <Button onClick={() => window.location.reload(true)}>
            Click here to refresh the page.
          </Button>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}
