import React, { Component } from 'react';
import { Redirect as BaseRedirect } from 'react-router';
import PropTypes from 'prop-types';

export default class Redirect extends Component {
  static contextTypes = {
    routes: PropTypes.any,
    addRoute: PropTypes.func,
    removeRoute: PropTypes.func,
  };

  componentWillMount() {
    this.create(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.destroy();
    this.create(nextProps);
  }

  componentWillUnmount() {
    this.destroy();
  }

  create(props) {
    this.context.addRoute({
      component: null,
      path: props.to,
      statusCode: props.statusCode || 302,
    });
  }

  destroy() {
    this.context.removeRoute(this.props.to);
  }

  render() {
    return <BaseRedirect {...this.props} />;
  }
}
