import {
  createStore as createBaseStore,
  combineReducers,
  applyMiddleware,
} from 'redux';
import { routerMiddleware, routerReducer } from 'react-router-redux';
import { autoRehydrate } from 'redux-persist';
import thunkMiddleware from 'redux-thunk';
import * as ownReducers from 'actions';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';

import analyticsMiddleware from './analyticsMiddleware';

export default function createStore(history, initialState = {}) {
  const reducers = {
    ...ownReducers,
    routing: routerReducer,
  };
  const middleware = [
    thunkMiddleware, // Allows for async action creators
    analyticsMiddleware, // Tracks pageviews from router location actions
    routerMiddleware(history), // Keep router history in sync with store
  ];
  const enhancers = [];

  // Set up client-only middleware and enhancers
  if (typeof window !== 'undefined') {
    middleware.unshift(require('./errorMiddleware').default); // Track uncaught exceptions from reducers
    enhancers.push(autoRehydrate()); // Rehydrate the store from cookies
  }

  return createBaseStore(
    combineReducers(reducers),
    initialState,
    composeWithDevTools(applyMiddleware(...middleware), ...enhancers)
  );
}
