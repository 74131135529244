import React, { PureComponent } from 'react';
import cn from 'classnames';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { withApollo } from '@apollo/client/react/hoc';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import firebase from 'firebase/app';
// import 'cqfill';

import withUser from 'components/withUser';
import jsonld from 'util/jsonld';
import { nextPage } from 'actions/onboarding';
import { autoHideToast, hideToast } from 'actions/toast';

import Header from 'components/Header';
import Footer from 'components/Footer';
import StreamLineFooter from 'components/Footer/StreamLineFooter';
import Toast from 'components/Toast';
import MarkAnalyticsUserType from './MarkAnalyticsUserType';
import config from '../../../config';
import SetBlurContext from './SetBlurContext';
import styles from './styles.module.scss';

class App extends PureComponent {
  state = {
    routes: this.props.routeCache,
    blur: false,
  };

  static propTypes = {
    client: PropTypes.object.isRequired,
    routeCache: PropTypes.array.isRequired,
    toastIsVisible: PropTypes.bool.isRequired,
    autoHideToast: PropTypes.func.isRequired,
    hideToast: PropTypes.func.isRequired,
    currentUser: PropTypes.object,
    history: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
    onboardingPhase: PropTypes.string.isRequired,
    baseURL: PropTypes.string,
  };

  static defaultProps = {
    currentUser: undefined,
    baseURL: undefined,
  };

  static contextTypes = {
    client: PropTypes.any,
  };

  static childContextTypes = {
    routes: PropTypes.any,
    addRoute: PropTypes.func,
    removeRoute: PropTypes.func,
    client: PropTypes.any,
  };

  getChildContext() {
    return {
      routes: this.state.routes,
      addRoute: this.addRoute,
      removeRoute: this.removeRoute,
      client: this.props.client,
    };
  }

  componentDidMount() {
    // check config for firebase exists
    if (
      !config.STUB_TWILIO &&
      Object.values &&
      Object.values(config.FIREBASE_CONFIG).every(Boolean)
    ) {
      if (firebase) {
        firebase.initializeApp(config.FIREBASE_CONFIG);
      }
    }
  }

  componentDidUpdate(nextProps) {
    if (typeof window !== 'undefined') {
      const routeOptions = this.getRouteOptions();
      const currentAndNextToastVisible =
        this.props.toastIsVisible === nextProps.toastIsVisible;

      if (
        !nextProps.currentUser &&
        routeOptions.showOnboardingCTA &&
        currentAndNextToastVisible
      ) {
        this.showOnboardingToast(nextProps);
      } else if (nextProps.toastIsVisible && this.showingOnboardingToast) {
        this.props.hideToast();
        this.showingOnboardingToast = false;
      }
    }
  }

  addRoute = (route) => {
    if (typeof window !== 'undefined') {
      this.setState((prevState) => ({
        routes: prevState.routes.concat([route]),
      }));
    } else {
      this.props.routeCache.push(route);
    }
  };

  removeRoute = (path) => {
    const filter = (route) => route.path !== path;
    if (typeof window !== 'undefined') {
      this.setState((prevState) => ({
        routes: prevState.routes.filter(filter),
      }));
    } else {
      this.props.routeCache.splice(this.props.routeCache.findIndex(filter), 1);
    }
  };

  showingOnboardingToast = false;

  showOnboardingToast(props = this.props) {
    // eslint-disable-next-line default-case
    switch (props.onboardingPhase) {
      case 'NOT_STARTED':
        this.showingOnboardingToast = true;
        this.props.autoHideToast(
          <Link to="/profile/size">
            <p>Start your office search with SquareFoot now&nbsp;&rsaquo;</p>
          </Link>
        );
        break;
      case 'SKIPPED_CTA':
        this.showingOnboardingToast = true;
        this.props.autoHideToast(
          <Link to="/profile/size">
            <p>
              Create a profile to get a customized tour of office
              spaces&nbsp;&rsaquo;
            </p>
          </Link>
        );
        break;
      case 'IN_PROGRESS':
        this.showingOnboardingToast = true;
        this.props.autoHideToast(
          <Link to="/profile/size">
            <p>
              Finish creating your profile to get a customized tour of office
              spaces&nbsp;&rsaquo;
            </p>
          </Link>
        );
        break;
    }
  }

  getRouteOptions() {
    let routeOptions = {
      showHeader: true,
      showFooter: true,
      showOnboarding:
        typeof window !== 'undefined' &&
        !this.props.currentUser &&
        this.props.history &&
        this.props.history.location.pathname !== '/' &&
        this.props.onboardingPhase === 'NOT_STARTED',
      isTourbook: false,
    };
    this.state.routes.forEach((route) => {
      if (route.options) {
        routeOptions = { ...routeOptions, ...route.options };
      }
    });
    return routeOptions;
  }

  hasInitialized = false;

  render() {
    const routeOptions = this.getRouteOptions();
    // Don't unnest this, webpack will remove it in production build
    if (process.env.NODE_ENV === 'development') {
      if (typeof window !== 'undefined') {
        config.BASE_URL = config.BASE_URL.replace(
          /\/\/[^:]+:/,
          `//${window.location.hostname}:`
        );
      } else {
        config.BASE_URL = config.BASE_URL.replace(
          /\/\/[^:]+:\d+/,
          `//${global.request.headers.host}`
        );
      }
    }

    const ecs_task = process.env.ECS_TASK || 'NOT_SET';

    return (
      <div className={cn('app-container', this.state.blur && styles.blur)}>
        <Helmet
          titleTemplate={`%s | ${config.SITE_NAME}`}
          defaultTitle={config.SITE_NAME}
          script={[
            {
              type: 'application/ld+json',
              innerHTML: JSON.stringify([
                jsonld('WebSite', {
                  name: config.SITE_NAME,
                  url: this.props.baseURL || config.BASE_URL,
                }),
                jsonld('Organization', {
                  name: config.SITE_NAME,
                  url: this.props.baseURL || config.BASE_URL,
                  logo: 'https://www.squarefoot.com/apple-touch-icon.png',
                  sameAs: [
                    config.SM_FACEBOOK,
                    config.SM_TWITTER,
                    config.SM_LINKEDIN,
                  ],
                  location: jsonld('Place', {
                    address: jsonld('PostalAddress', {
                      streetAddress: config.CONTACT_ADDRESS,
                      addressLocality: config.CONTACT_CITY,
                      addressRegion: config.CONTACT_STATE,
                      postalCode: config.CONTACT_ZIP,
                    }),
                  }),
                }),
              ]),
            },
          ]}
        >
          <html lang="en-US" />
          {typeof window === 'undefined' && (
            <base href={this.props.baseURL || config.BASE_URL} />
          )}
          <meta name="description" content={config.SITE_DESCRIPTION} />
          <meta name="og:site_name" content={config.SITE_NAME} />
          <meta name="application-name" content={config.SITE_NAME} />
          <meta name="apple-mobile-web-app-title" content={config.SITE_NAME} />
          {routeOptions.isTourbook && (
            <meta
              name="apple-itunes-app"
              content={`app-id=${config.APP_STORE_ID}`}
            />
          )}
          <meta name="theme_color" content={config.THEME_COLOR} />
          <meta name="fb:app_id" content={config.FB_APP_ID} />
        </Helmet>
        <Toast />
        {typeof window !== 'undefined' && <MarkAnalyticsUserType />}
        <SetBlurContext.Provider value={(blur) => this.setState({ blur })}>
          {routeOptions.showHeader && <Header />}
          <main>{this.props.children}</main>
          {routeOptions.showFooter && (
            <Footer showAppLink={routeOptions.isTourbook} />
          )}
          {!routeOptions.showFooter && routeOptions.showStreamLineFooter && (
            <StreamLineFooter />
          )}
        </SetBlurContext.Provider>
        <div className={cn('ecs_task_identifier', styles.ecs_task_identifier)}>
          {`ECS_TASK_${ecs_task}`}
        </div>
      </div>
    );
  }
}

export default compose(
  connect(
    (state) => ({
      onboardingPhase: state.onboarding.phase,
      toastIsVisible: state.toast.visible,
    }),
    {
      nextPage,
      autoHideToast,
      hideToast,
    }
  ),
  withUser,
  withApollo,
  withRouter
)(App);
