import React, { PureComponent } from 'react';
import SignInPage from 'routes/SignInPage';
import UnauthorizedPage from 'routes/UnauthorizedPage';
import withUser from 'components/withUser';
import Route from 'components/Route';
import { track, analyticsEvents } from '../../util/analytics';

class ProtectedRoute extends PureComponent {
  static defaultProps = {
    check: () => true,
    signedOutLoader: SignInPage,
    unauthorizedLoader: UnauthorizedPage,
  };

  componentDidMount() {
    if (!this.props.currentUser) {
      track(analyticsEvents.logged_out_protected_route);
    }
  }

  render() {
    const { check, currentUser, ...props } = this.props;
    if (!currentUser) {
      props.component = this.props.signedOutLoader;
    } else if (!check(currentUser)) {
      props.component = this.props.unauthorizedLoader;
    }

    return <Route {...props} />;
  }
}

export default withUser(ProtectedRoute);
