/* eslint-disable quote-props */
const cities = {
  'New York': '/ny/new-york',
  Houston: '/tx/houston',
  Dallas: '/tx/dallas',
  Phoenix: '/az/phoenix',
  'Los Angeles': '/ca/los-angeles',
  Austin: '/tx/austin',
  Chicago: '/il/chicago',
  Miami: '/fl/miami',
  Indianapolis: '/in/indianapolis',
  Atlanta: '/ga/atlanta',
  Charlotte: '/nc/charlotte',
  Denver: '/co/denver',
  Brooklyn: '/ny/brooklyn',
  Tucson: '/az/tucson',
  Washington: '/dc/washington',
  Irving: '/tx/irving',
  Boston: '/ma/boston',
  Tulsa: '/ok/tulsa',
  Tampa: '/fl/tampa',
  Columbus: '/oh/columbus',
  Cincinnati: '/oh/cincinnati',
  'Farmers Branch': '/tx/farmers-branch',
  Louisville: '/ky/louisville',
  Albuquerque: '/nm/albuquerque',
  'San Diego': '/ca/san-diego',
  Columbia: '/sc/columbia',
  Memphis: '/tn/memphis',
  Omaha: '/ne/omaha',
  Richardson: '/tx/richardson',
  Pittsburgh: '/pa/pittsburgh',
  Plano: '/tx/plano',
  Scottsdale: '/az/scottsdale',
  'San Antonio': '/tx/san-antonio',
  'Oklahoma city': '/ok/oklahoma-city',
  Philadelphia: '/pa/philadelphia',
  Southfield: '/mi/southfield',
  Detroit: '/mi/detroit',
  Arlington: '/va/arlington',
  Richmond: '/va/richmond',
  Seattle: '/wa/seattle',
  Rockville: '/md/rockville',
  Baltimore: '/md/baltimore',
  Cleveland: '/oh/cleveland',
  Minneapolis: '/mn/minneapolis',
  Akron: '/oh/akron',
  Mesa: '/az/mesa',
  Carlsbad: '/ca/carlsbad',
  'San Francisco': '/ca/san-francisco',
  'Las Vegas': '/nv/las-vegas',
  Orlando: '/fl/orlando',
  Jacksonville: '/fl/jacksonville',
  Nashville: '/tn/nashville',
  Lansing: '/mi/lansing',
  'Fort Worth': '/tx/fort-worth',
  'Sugar Land': '/tx/sugar-land',
  'Fairfield County': '/ct/fairfield-county',
  Toronto: '/on/toronto',
  'White Plains': '/ny/white-plains',
};
export default cities;
export const getSlugFromCityName = (cityName) => cities[cityName];
