import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import H2 from 'components/deprecated/legacyElements/H2';

import config from '../../../../config';
import styles from '../style.module.scss';

const UpperFooter = (props) => (
  <div className={`${styles.upper} ${props.className}`}>
    <div className={`inset ${styles.responsiveFlex}`}>
      <div className={styles.innerContainerCol2}>
        <address className={styles.item}>
          <H2 footer>SquareFoot HQ</H2>
          <p className={styles.address}>
            <a href={`tel:${config.CONTACT_NUMBER_VALUE}`}>
              <span>{config.CONTACT_NUMBER_DISPLAY}</span>
            </a>
            <br />
            {config.CONTACT_ADDRESS}
            <br />
            {config.CONTACT_CITY}, {config.CONTACT_STATE} {config.CONTACT_ZIP}
          </p>
          <ul className={styles.socialMedia}>
            <li>
              <a
                href={config.SM_FACEBOOK}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img alt="Facebook" src={require('../facebook.png')} />
              </a>
            </li>
            <li>
              <a
                href={config.SM_TWITTER}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img alt="Twitter" src={require('../twitter.png')} />
              </a>
            </li>
            <li>
              <a
                href={config.SM_INSTAGRAM}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img alt="Instagram" src={require('../instagram.png')} />
              </a>
            </li>
            <li>
              <a
                href={config.SM_LINKEDIN}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img alt="LinkedIn" src={require('../linkedin.png')} />
              </a>
            </li>
          </ul>
        </address>

        <nav className={styles.item}>
          <H2 footer>Company</H2>
          <ul>
            <li>
              <a href="/about">About</a>
            </li>
            <li className="desktop-only">
              <a href="/about/#clients">Clients</a>
            </li>
            <li>
              <a href="/welcome/meet-our-team">Team</a>
            </li>
            <li>
              <a href="/blog">Blog</a>
            </li>
          </ul>
        </nav>
      </div>
      <div className={styles.innerContainerCol2}>
        <nav className={styles.item}>
          <H2 footer>Resources</H2>
          <ul>
            <li>
              <Link to="/office-space-calculator">Space Calculator</Link>
            </li>
            <li>
              <Link to="/commutes">Commute Calculator</Link>
            </li>
            <li>
              <a href="/leasopedia">Leasopedia</a>
            </li>
            <li className="desktop-only">
              <a href="/leasopedia/#office-search-guide">Office Search Guide</a>
            </li>
            <li>
              <a href="/move-in-services">Move in Services</a>
            </li>
          </ul>
        </nav>

        <nav className={styles.item}>
          <H2 footer>Connect</H2>
          <ul>
            <li>
              <a href="/contact-us">Contact</a>
            </li>
            <li>
              <a href="/jobs">Careers</a>
            </li>
            <li>
              <a href="/referrals">Referral</a>
            </li>
            <li>
              <a href="/contact-us/#list-with-us">List Your Property</a>
            </li>
          </ul>
        </nav>
      </div>
      <div className={styles.innerContainerCol1}>{props.children}</div>
    </div>
  </div>
);

UpperFooter.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};

UpperFooter.defaultProps = {
  children: null,
  className: '',
};

export default UpperFooter;
