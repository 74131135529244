const config = {
  SITE_NAME: 'SquareFoot',
  SITE_DESCRIPTION: 'We’re a new kind of commercial real estate company. We help growing businesses win at finding their next office space, providing flexibility, transparency, and a stress-free process along the way.',

  CONTACT_ADDRESS: '115 W 30th Street, Suite 900',
  CONTACT_CITY: 'New York City',
  CONTACT_STATE: 'NY',
  CONTACT_ZIP: '10001',
  CONTACT_NUMBER_DISPLAY: '(917) 994-1062',
  CONTACT_NUMBER_VALUE: '+9179941062',
  SQUAREFOOT_HQ_COORDS: { lat: 40.741047, lng: -73.992917 },

  SM_FACEBOOK: 'https://www.facebook.com/TheSquareFoot',
  SM_TWITTER: 'https://twitter.com/thesqft',
  SM_LINKEDIN: 'https://www.linkedin.com/company/2220452',
  SM_INSTAGRAM: 'https://www.instagram.com/squarefoot_/',

  APP_STORE_ID: '1124552660',
  APP_STORE_URL: 'https://itunes.apple.com/us/app/squarefoot-mobile/id1124552660?mt=8',

  THEME_COLOR: '#ffffff',
  ICOMOON_CSS: 'https://d1azc1qln24ryf.cloudfront.net/97723/miyagi/style-cf.css?sb81ww',

  GOOGLE_MAPS_API_KEY: 'AIzaSyC2_phtcUn04WIqZGQIhCFZSUPPsWezev0',
  GOOGLE_MAPS_STATIC_MAP_API_KEY: 'AIzaSyB_HUAJLjmcIKcN-hiYb-tspTzRVyxEqoI',
  GOOGLE_MAPS_STATIC_MAP_MARKER_URL: 'https://s3.amazonaws.com/thesquarefoot-assets-production/static-maps/pin@3x.png',
  GOOGLE_MAPS_STATIC_MAP_MARKER_URL_CLIENT_SEARCH: 'https://s3.amazonaws.com/thesquarefoot-assets-production/static-maps/pin@2x.png',

  FB_APP_ID: '496698383783212',
  SEGMENT_SEO_PAGE_WRITE_KEY: 'ZbaETc7CYStoTnBNvzsOnjAD6oP4p1Sn',
  MATTERPORT_API_KEY: 'addd8e0221ca4a31bb992f8ef8b674b1',
  MATTERPORT_SDK_VERSION: '3.0',
  PORT: 4000,

  // ENV vars that need to be included at build time (Travis)
  FIREBASE_CONFIG: {
    apiKey: process.env.FIREBASE_API_KEY,
    authDomain: process.env.FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.FIREBASE_DATABASE_URL,
    projectId: process.env.FIREBASE_PROJECT_ID,
    storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.FIREBASE_APP_ID,
  },
  ASSET_PATH: process.env.ASSET_PATH,
  SEGMENT_WRITE_KEY: process.env.SEGMENT_WRITE_KEY,
  API_URL: process.env.API_URL,
  BASE_URL: process.env.BASE_URL,

  // ENV vars that need to be set both at build (Travis) and run time (via ECS Task Def)
  AIRBRAKE_PROJECT_ID: process.env.AIRBRAKE_PROJECT_ID,
  AIRBRAKE_PROJECT_KEY: process.env.AIRBRAKE_PROJECT_KEY,
  AIRBRAKE_ENV: process.env.AIRBRAKE_ENV,
  STUB_TWILIO: process.env.STUB_TWILIO,
};

module.exports = config;
