const initialState = {
  visible: false,
  contents: '',
};

export const autoHideToast =
  (contents, hideAfterDelay = 3000, isError = false) =>
  (dispatch) => {
    dispatch({ type: 'TOAST/SHOW', contents, isError });
    if (hideAfterDelay) {
      setTimeout(() => dispatch({ type: 'TOAST/HIDE' }), hideAfterDelay);
    }
  };
export const hideToast = () => ({ type: 'TOAST/HIDE' });

export const showToast = (contents) => (dispatch) => {
  dispatch({ type: 'TOAST/SHOW', contents });
};

export const promiseWithToast =
  (
    mutationPromise,
    successMessage = 'Success!',
    failureMessage = 'Saving failed, please try again later.'
  ) =>
  (dispatch) =>
    mutationPromise
      .then((response) => {
        dispatch(autoHideToast(successMessage));
        return response;
      })
      .catch((err) => {
        dispatch(autoHideToast(failureMessage, 5000));
        console.error(err);
        return err;
      });

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'TOAST/SHOW':
      return {
        visible: true,
        contents: action.contents,
        isError: action.isError,
      };

    case 'TOAST/HIDE':
      return {
        visible: false,
      };

    default:
      return state;
  }
}
