import React, { PureComponent } from 'react';
import Helmet from 'react-helmet';
import SignInForm from 'components/SignInForm';
import H1 from 'components/deprecated/legacyElements/H1';
import PropTypes from 'prop-types';
import styles from './style.module.scss';
import { splitQueryString } from '../../util/strings';

export default class SignInPage extends PureComponent {
  static propTypes = {
    location: PropTypes.shape({
      state: PropTypes.string,
      search: PropTypes.string,
    }),
  };

  static defaultProps = {
    location: {},
  };

  render() {
    const { redirectTo } = splitQueryString(this.props?.location?.search || '');
    return (
      <div className={`inset ${styles.container}`}>
        <Helmet title="Sign in" />
        <H1 inset>Sign in</H1>
        <SignInForm
          email={
            this.props.location.state ? this.props.location.state.email : ''
          }
          redirectTo={redirectTo}
        />
      </div>
    );
  }
}
