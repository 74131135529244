import { trackPageView } from 'util/analytics';

// eslint-disable-next-line no-unused-vars
const analyticsMiddleware = (store) => (next) => (action) => {
  const result = next(action);
  if (action.type === '@@router/LOCATION_CHANGE') {
    // Delay tracking by a tick so that the browser history state fully updates
    setImmediate(
      trackPageView.bind(null, {
        path: action.payload.pathname + action.payload.hash,
      })
    );
  }
  return result;
};

export default analyticsMiddleware;
