// This client can be required for spot error reporting:
// >  import airbrake from './util/airbrake';
// >  airbrake.notify(error);
import { Notifier } from '@airbrake/browser';
import config from '../../config';

let environment = config.AIRBRAKE_ENV;

// Check if this is being called in node or browser so we have more specific understanding of the error
if (typeof window !== 'undefined' && window.document) {
  environment += ' client';
} else {
  environment += ' server';
}

export default new Notifier({
  projectId: config.AIRBRAKE_PROJECT_ID,
  projectKey: config.AIRBRAKE_PROJECT_KEY,
  environment,
});
