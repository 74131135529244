import PropTypes from 'prop-types';
import React from 'react';

import UpperFooter from './UpperFooter';
import LowerFooter from './LowerFooter';
import styles from './style.module.scss';

const Footer = (props) => {
  const showLower = !props.showAppLink;

  return (
    <footer className={styles.container}>
      <UpperFooter />
      {showLower && <LowerFooter />}
    </footer>
  );
};

Footer.propTypes = {
  showAppLink: PropTypes.bool,
};
Footer.defaultProps = {
  showAppLink: false,
};

export default Footer;
