import React from 'react';
import { loadableReady } from '@loadable/component';
import { hydrate, unmountComponentAtNode } from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import { createBrowserHistory } from 'history';
import { ApolloProvider } from '@apollo/client';
import { persistStore } from 'redux-persist';
import { createTrackingCookie } from 'util/adwords';
import CurrentUserQuery from 'queries/CurrentUserQuery.gql';
import { login } from 'actions/user';
import { updateSearch } from 'actions/search';

import createApolloClient from './apollo';
import createStore from './store';

// Import routes mutably so they can be hot reloaded in development
let Routes = require('./routes').default;

// Set up Redux store with Apollo data and history integration
const client = createApolloClient(window.__APOLLO_STATE__);
const history = createBrowserHistory();
const store = createStore(history, window.__REDUX_STATE__);

// Persist Redux store on the client and rehydrate automatically
persistStore(store, {
  whitelist: ['onboarding', 'tourbook', 'dataUpdates'],
});

const rootElement = document.getElementById('app');

const renderWithWrapper = () =>
  hydrate(
    <ApolloProvider client={client}>
      <ReduxProvider store={store}>
        <ConnectedRouter history={history}>
          <Routes routeCache={[]} />
        </ConnectedRouter>
      </ReduxProvider>
    </ApolloProvider>,
    rootElement
  );

if (!window.__REDUX_STATE__) {
  client
    .query({ query: CurrentUserQuery })
    .then(({ data: { currentUser } }) => {
      if (currentUser?.cityOfInterest) {
        store.dispatch(
          updateSearch({
            numEmployees:
              currentUser.latestCampaign.currentActiveRequirements
                ?.numEmployees,
            neighborhoods:
              currentUser.latestCampaign.currentActiveRequirements?.neighborhoods?.map(
                ({ name }) => name
              ) || [],
            cityName: currentUser.cityOfInterest,
          })
        );
      }
      store.dispatch(login(currentUser));
    })
    .then(() => {
      loadableReady(() => {
        renderWithWrapper();
      });
    });
} else {
  loadableReady(() => {
    renderWithWrapper();
  });
}

// Set GCLID tracking cookie for AdWords on page load
createTrackingCookie();

// Route change event handler
history.listen((location) => {
  if (location.state && location.state.persistScrollPosition) return;

  window.scrollTo(0, 0);
});

// Enable webpack hot reloading for the old dev server
if (module.hot) {
  module.hot.accept(require.resolve('./routes'), () => {
    // On hot reloads, refresh the router and re-match routes
    Routes = require('./routes').default;
    unmountComponentAtNode(rootElement);
    renderWithWrapper();
  });
}
