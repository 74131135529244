import { dataIdFromObject } from 'init/apollo';
import ImporterJobsQuery from 'queries/ImporterJobsQuery.gql';
import BrokerListingDrawerQuery from 'routes/TourbookPage/broker/BrokerListingDrawer/BrokerListingDrawerQuery.gql';
import { isJobRunning } from 'domain/job';
import cloneDeep from 'lodash/cloneDeep';
import dotProp from 'dot-prop-immutable';
import { autoHideToast } from './toast';

export const LISTING_UPDATE_PATHS = {
  Listing: [],
  Building: 'building',
  BrokerInsight: 'brokerInsights',
  Contact: 'contacts',
};

export const updateData = (payload) => {
  const items = Array.isArray(payload) ? payload : [payload];
  return { type: 'DATA_UPDATES/UPDATE', payload: items };
};

export const clearDataUpdates = () => ({ type: 'DATA_UPDATES/CLEAR' });

export function awaitListingUpdates(apollo, allUpdates) {
  const jobIds = allUpdates.reduce((jobs, { data: { userChangeListing } }) => {
    const jobId = userChangeListing && userChangeListing.importerJobId;
    return jobId ? [...jobs, jobId] : jobs;
  }, []);
  if (!jobIds.length) return Promise.resolve();

  async function checkJobsComplete(resolve, reject) {
    const { data } = await apollo.query({
      query: ImporterJobsQuery,
      variables: { ids: jobIds },
      fetchPolicy: 'no-cache',
    });
    const unterminatedJobs = [];
    const failedJobs = [];
    data.importerJobs.forEach((job) => {
      if (isJobRunning(job)) {
        unterminatedJobs.push(job);
      } else if (job.status !== 'SUCCESS') {
        failedJobs.push(job);
      }
    });

    if (unterminatedJobs.length > 0) {
      return setTimeout(checkJobsComplete, 500, resolve, reject);
    }
    if (failedJobs.length > 0) {
      const debugInfo = failedJobs
        .map((job) => `(${job.id}, ${job.status})`)
        .join(', ');
      return reject(`Failed jobs: ${debugInfo}`);
    }
    return resolve(data.importerJobs);
  }

  return new Promise(checkJobsComplete);
}

function requeryUpdatedListings(apollo, listings) {
  const promises = listings.map((listing) =>
    apollo.query({
      query: BrokerListingDrawerQuery,
      variables: { listingId: listing.id, imageUploadIds: [] },
      fetchPolicy: 'network-only',
    })
  );
  return Promise.all(promises);
}

export const saveDataUpdates =
  (apollo, mutationsByType) => (dispatch, getState) => {
    const { dataUpdates } = getState();
    const listingUpdates = Object.values(dataUpdates).filter(
      (item) => item.__typename === 'Listing'
    );
    const boundMutations = Object.values(dataUpdates).map((item) => {
      const { __typename: typename, ...variables } = item;
      const mutation = mutationsByType[typename];
      if (!mutation)
        throw new Error(
          `Can't save type "${typename}" with keys: ${Object.keys(variables)}`
        );
      return apollo.mutate({ mutation, variables });
    });

    return Promise.all(boundMutations)
      .then((updates) => awaitListingUpdates(apollo, updates))
      .then((response) =>
        requeryUpdatedListings(apollo, listingUpdates).then(() => response)
      )
      .then((response) => {
        dispatch({ type: 'DATA_UPDATES/SAVE_SUCCESS' });
        dispatch(autoHideToast('Changes saved!'));
        return response;
      })
      .catch((error) => {
        // Log errors for manual QA/debugging
        console.log(`Error uploading saved data. ${error.toString()}`); // eslint-disable-line no-console
        dispatch(
          autoHideToast(
            'There was a problem updating your listing. Please contact the #bugs channel on slack',
            5000
          )
        );
      });
  };

export const getDataWithUpdates = (listing, allDataUpdates) => {
  let mergedListing = cloneDeep(listing);
  const listingKey = dataIdFromObject(listing);
  const buildingKey = dataIdFromObject(listing.building);

  Object.entries(allDataUpdates).forEach(([key, localUpdate]) => {
    const shouldBeMerged =
      localUpdate.listingId === listing.id ||
      key === listingKey ||
      key === buildingKey;
    const path = LISTING_UPDATE_PATHS[localUpdate.__typename];

    if (shouldBeMerged) {
      mergedListing = dotProp.merge(mergedListing, path, localUpdate);
    }
  });

  return mergedListing;
};

export default function reducer(state = {}, action) {
  switch (action.type) {
    case 'DATA_UPDATES/UPDATE': {
      return action.payload.reduce((dataUpdatesState, updates) => {
        if (
          !updates.hasOwnProperty('__typename') ||
          !updates.hasOwnProperty('id')
        ) {
          throw new Error(
            'Cannot update a record without id and __typename set'
          );
        }

        // only update records with keys other than id and typename
        if (Object.keys(updates).length < 2) return dataUpdatesState;

        const key = dataIdFromObject(updates);

        return dotProp.merge(dataUpdatesState, key, updates);
      }, state);
    }
    case 'DATA_UPDATES/CLEAR':
    case 'DATA_UPDATES/SAVE_SUCCESS':
      return {};
    default:
      return state;
  }
}
