import React from 'react';
import styles from './style.module.scss';

export default ({ children }) => (
  <>
    {children}
    <span className={styles.first}>.</span>
    <span className={styles.second}>.</span>
    <span className={styles.third}>.</span>
  </>
);
