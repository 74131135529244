export const isBroker = (user) => user && user.isBroker;
export const isAdmin = (user) => user && user.isAdmin;
export const hasRequestedAppointment = (user) =>
  Boolean(
    user &&
      user.completedUserTasks &&
      user.completedUserTasks.find(
        (t) => t.task === 'request_appointment_engagement_box'
      )
  );
export const hasFlag = (user, value) =>
  Boolean(user && user.featureFlags && user.featureFlags.indexOf(value) !== -1);
