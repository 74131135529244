import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import BaseModal from 'components/modals/Modal';
import styles from './styles.module.scss';

const Modal = ({ onClose, sideContent, children, className }) => (
  <BaseModal
    className={cn(styles.modal, className)}
    containerClassName={styles.container}
    basicStyles
    onClose={onClose}
  >
    <span
      className={styles.contentWrapper}
      aria-hidden
      onClick={(e) => e.stopPropagation()}
      onKeyPress={() => {}}
    >
      {sideContent && <div className={styles.sideContent}>{sideContent}</div>}
      <div className={styles.mainContent}>
        <button
          type="button"
          className={styles.closeButton}
          data-jest="modalCloseButton"
          onClick={onClose}
        >
          ×
        </button>
        <div>{children}</div>
      </div>
    </span>
  </BaseModal>
);

Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  sideContent: PropTypes.node,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Modal.defaultProps = {
  sideContent: null,
  className: '',
};

export default Modal;
