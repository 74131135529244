import { ApolloClient, InMemoryCache } from '@apollo/client';
import createBatchingHttpLink from './createBatchingHttpLink';
import errorHandlingLink from './errorHandlingLink';
import config from '../../../config';
import possibleTypes from './possibleTypes.json';

export const dataIdFromObject = (obj) =>
  obj?.id && obj?.__typename ? obj.__typename + obj.id : null;

export default function createApolloClient(
  initialState = {},
  options = {},
  apiUrl = config.API_URL,
  cookies
) {
  const fetchOptions = {
    // This should be 'same-origin', but this isn't supported by whatwg-fetch
    credentials: process.env.NODE_ENV === 'test' ? 'same-origin' : 'include',
  };
  if (cookies || typeof document !== 'undefined') {
    fetchOptions.headers = { cookie: cookies || document.cookie };
  }

  const httpLink = createBatchingHttpLink({
    uri: apiUrl,
    ...fetchOptions,
  });
  const cache = new InMemoryCache({ possibleTypes });

  // Internal URL has to be resolved at run-time, not compile-time
  // so it can be tested against the public URL
  return new ApolloClient({
    link: errorHandlingLink.concat(httpLink),
    cache: cache.restore(initialState),
    queryDeduplication: true,
    dataIdFromObject,
    ...options,
  });
}
