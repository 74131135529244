export function setCookie(name, value, days) {
  if (typeof document !== 'undefined') {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = `${name}=${value}; expires=${date.toUTCString()}`;
  }
}

export function getCookie(name) {
  const cookie = typeof document !== 'undefined' ? document.cookie : '';
  const value = cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');
  return value ? value.pop() : '';
}

export function parseCookies(cookieString = '') {
  return cookieString
    .split(';')
    .map((kv) => kv.trim().split('='))
    .filter((kv) => kv.length === 2)
    .reduce((acc, [key, val]) => {
      const strippedVal = val[0] === '"' ? val.slice(1, -1) : val;
      return Object.assign(acc, { [key]: strippedVal });
    }, {});
}
