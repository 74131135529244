import React from 'react';

import styles from './styles.module.scss';

export default () => (
  <footer className={styles.footer}>
    <div className={styles.container}>
      <div className={styles.item}>
        <h3 className={styles.title}>Have a question?</h3>
      </div>
      <div className={styles.item}>
        <p className={styles.paragraph}>
          We are happy to help,
          <br />
          ring us at{' '}
          <a href="tel:(646) 768-8366" className={styles.tel} rel="nofollow">
            (646) 768-8366
          </a>
        </p>
      </div>
      <div className={styles.item}>
        <p className={styles.paragraph}>
          Send your query to
          <br />
          <a className={styles.link} href="mailto:contact@squarefoot.com">
            contact@squarefoot.com
          </a>
        </p>
      </div>
      <div className={styles.item}>
        <p className={styles.paragraph}>
          <a className={styles.link} href="https://www.squarefoot.com/privacy/">
            Privacy
          </a>
        </p>
      </div>
    </div>
  </footer>
);
