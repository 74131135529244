import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './styles.module.scss';

const Body = (props) => (
  <span {...props} className={cn(styles.body, props.className)} />
);

Body.propTypes = {
  className: PropTypes.string,
};

export default Body;
