import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route as BaseRoute } from 'react-router';
import ErrorBoundary from 'components/ErrorBoundary';
import Spinner from './Spinner';

const getUnwrappedComponent = (component) => {
  let c = component;
  while (c.WrappedComponent) c = c.WrappedComponent;
  return c;
};

export default class Route extends Component {
  static contextTypes = {
    routes: PropTypes.any,
    addRoute: PropTypes.func,
    removeRoute: PropTypes.func,
  };

  state = {
    loadedModule: Spinner,
  };

  componentWillMount() {
    this.create(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.loader !== this.props.loader ||
      (nextProps.component !== this.props.component &&
        getUnwrappedComponent(nextProps.component).toString() !==
          getUnwrappedComponent(this.props.component).toString())
    ) {
      this.setState({ loadedModule: Spinner });
      this.destroy();
      this.create(nextProps);
    }
  }

  componentWillUnmount() {
    this.destroy();
  }

  create(props) {
    if (props.component) {
      this.setLoadedComponent(props.component, props.path, props.options);
    } else if (props.loader && props.loader.prototype instanceof Component) {
      const loaderComponent = props.loader;
      this.setLoadedComponent(loaderComponent, props.path, props.options);
    } else {
      props.loader((mod) => {
        const loadedModule = mod.default ? mod.default : mod;
        this.setLoadedComponent(loadedModule, props.path, props.options);
      });
    }
  }

  destroy() {
    this.context.removeRoute(this.props.path);
  }

  setLoadedComponent(component, path, options) {
    this.context.addRoute({
      name: this.props.name,
      component,
      statusCode: this.props.statusCode || 200,
      path,
      options,
    });
    this.setState({ loadedModule: component });
  }

  render() {
    const LoadedModule = this.props.component || this.props.loader;
    return (
      <ErrorBoundary>
        <BaseRoute {...this.props} component={LoadedModule} />
      </ErrorBoundary>
    );
  }
}

Route.propTypes = {
  // The name of the file in the 'routes/` direction
  name: PropTypes.string,
};
