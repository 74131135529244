import { setCookie, getCookie } from './cookies';

// Google AdWords GCLID tracking (this is just a formatted version of their snippet)

function getParam(param) {
  const paramMatch = RegExp('[?&]' + param + '=([^&]*)').exec(
    window.location.search
  );
  return paramMatch && decodeURIComponent(paramMatch[1].replace(/\+/g, ' '));
}

export function createTrackingCookie() {
  const gclid = getParam('gclid');
  if (gclid) {
    const gclsrc = getParam('gclsrc');
    if (!gclsrc || gclsrc.indexOf('aw') !== -1) {
      setCookie('gclid', gclid, 90);
    }
  }
}

export default function getTrackingCookie() {
  return getCookie('gclid');
}
