import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { CaptionEmphasis } from 'components/componentLibrary/typography';
import styles from './styles.module.scss';

const TextButton = ({ className, children, 'data-qa': dataQa, ...props }) => (
  <button
    data-qa={dataQa}
    {...props}
    className={cn(styles.textButton, className)}
  >
    <CaptionEmphasis>{children}</CaptionEmphasis>
  </button>
);

TextButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  'data-qa': PropTypes.string,
};

TextButton.defaultProps = {
  className: undefined,
  'data-qa': 'text-button',
};

export default TextButton;
