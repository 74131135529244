let airbrake;
if (
  process.env.SQRFT_ENVIRONMENT === 'production' ||
  process.env.SQRFT_ENVIRONMENT === 'snapshot'
) {
  airbrake = require('util/airbrake').default;
} else {
  airbrake = {
    notify(error) {
      console.error(error); // eslint-disable-line no-console
    },
  };
}

const errorMiddleware = (store) => (next) => (action) => {
  // eslint-disable-line no-unused-vars
  try {
    const processedAction = next(action);
    switch (action.type) {
      case 'APOLLO_QUERY_ERROR':
      case 'APOLLO_MUTATION_ERROR': {
        const state = store.getState();

        let query;
        if (action.queryId) {
          query = state.apollo.queries[action.queryId];
        } else if (action.mutationId) {
          query = state.apollo.mutations[action.mutationId];
        }

        if (query) {
          const filteredVariables = { ...query.variables };
          delete filteredVariables.password;
          query = {
            ...query,
            variables: filteredVariables,
          };
        }

        airbrake.notify({
          error: action.error,
          context: { source: 'redux-graphql' },
          params: { action, query },
        });
      }
      // eslint-disable-next-line no-fallthrough
      default:
        return processedAction;
    }
  } catch (error) {
    airbrake.notify({
      error,
      context: { source: 'redux' },
      params: { action },
    });
    return error;
  }
};

export default errorMiddleware;
