import React from 'react';
import { Switch } from 'react-router';
import Route from 'components/Route';
import ProtectedRoute from 'components/ProtectedRoute';
import loadable from '@loadable/component';

import App from 'components/App';
import Redirect from 'components/Redirect';

import { isAdmin, isBroker } from 'domain/user';
import { replaceSearchURL } from 'actions/search';
import { BUILDING_PATH_REGEX } from 'util/constants';

const HomePage = loadable(() => import('routes/HomePage'));
const PublicSearchPage = loadable(() => import('routes/PublicSearchPage'));
const OnboardingPage = loadable(() => import('routes/OnboardingPage'));
const ExternalOnboarding = loadable(() => import('routes/ExternalOnboarding'));
const ForgotPasswordPage = loadable(() =>
  import('routes/ForgotPassword/ForgotPasswordPage')
);
const ResetPasswordPage = loadable(() =>
  import('routes/ForgotPassword/ResetPasswordPage')
);
const SignInPage = loadable(() => import('routes/SignInPage'));
const SignOutPage = loadable(() => import('routes/SignOutPage'));
const TourbookPage = loadable(() => import('routes/TourbookPage'), {
  ssr: false,
});
const InvitationPage = loadable(() => import('routes/InvitationPage'));
const BuildingPage = loadable(() => import('routes/BuildingPage'));
const CommuteCalculatorPage = loadable(() =>
  import('routes/CommuteCalculatorPage')
);
const SpaceCalculatorPage = loadable(() =>
  import('routes/SpaceCalculatorPage')
);
const BuildingAdminPage = loadable(() => import('routes/BuildingAdminPage'));
const UserAdminPage = loadable(() => import('routes/UserAdminPage'));
const FeaturedListingsAdminPage = loadable(() =>
  import('routes/FeaturedListingsAdminPage')
);
const StyleGuidePage = loadable(() => import('routes/StyleGuidePage'));
const SitemapPage = loadable(() => import('routes/SitemapPage'));
const Error404Page = loadable(() => import('routes/Error404Page'));
const LoggedInListingPage = loadable(() =>
  import('routes/LoggedInListingPage')
);
const CSVUploadPage = loadable(() => import('routes/CSVUploadPage'), {
  ssr: false,
});
const SettingsPage = loadable(() => import('routes/SettingsPage'), {
  ssr: false,
});
const DebugInfoPage = loadable(() => import('routes/DebugInfoPage'));
const ImageSlideshowPage = loadable(() => import('routes/ImageSlideshowPage'));
const AccountConfirmationPage = loadable(() =>
  import('routes/AccountConfirmationPage')
);
const ImporterPage = loadable(() => import('routes/ImporterPage'));
const MaintenancePage = loadable(() => import('routes/MaintenancePage'));
const ClientSearchListingPage = loadable(() =>
  import('routes/ClientSearchListingPage')
);
const PublicSurveyPage = loadable(
  () => import('routes/Surveys/PublicSurveyPage'),
  {
    ssr: false,
  }
);
const ResearchTeamBuildingContactBuildings = loadable(
  () => import('routes/ResearchTeamBuildingContactBuildingsPage'),
  { ssr: false }
);
const ResearchTeamBuildingContacts = loadable(
  () => import('routes/ResearchTeamBuildingContactsPage'),
  { ssr: false }
);
const ResearchTeamBuildingBuildingContactBuilding = loadable(
  () => import('routes/ResearchTeamBuildingContactBuildingPage'),
  { ssr: false }
);
const ResearchTeamBuildingBuildingContactBuildingEditListing = loadable(
  () => import('routes/ResearchTeamBuildingContactBuildingEditListingPage'),
  { ssr: false }
);
const ResearchTeamCreateBuildingContact = loadable(
  () => import('routes/ResearchTeamBuildingContactCreateContactPage'),
  { ssr: false }
);
const ResearchTeamEditBuildingContact = loadable(
  () => import('routes/ResearchTeamBuildingContactEditContactPage'),
  { ssr: false }
);
const ResearchTeamBuildingContactAddBuilding = loadable(
  () => import('routes/ResearchTeamBuildingContactAddBuildingPage'),
  { ssr: false }
);

const LeasingManagerPage = loadable(() => import('routes/LeasingManagerPage'), {
  ssr: false,
});

export default function Routes({ routeCache, appProps }) {
  const tourbookRouteOptions = {
    showFooter: false,
    showOnboardingCTA: false,
    isTourbook: true,
  };
  return (
    <App routeCache={routeCache} {...(appProps || {})}>
      <Switch>
        <Route
          path="/research-team/building-contacts"
          component={ResearchTeamBuildingContacts}
          options={{ showHeader: false, showFooter: false }}
          exact
        />
        <Route
          path="/research-team/building-contacts/create"
          component={ResearchTeamCreateBuildingContact}
          options={{ showHeader: false, showFooter: false }}
          exact
        />
        <Route
          path="/research-team/building-contacts/:buildingContactId"
          component={ResearchTeamBuildingContactBuildings}
          options={{ showHeader: false, showFooter: false }}
          exact
        />
        <Route
          path="/research-team/building-contacts/:buildingContactId/edit"
          component={ResearchTeamEditBuildingContact}
          options={{ showHeader: false, showFooter: false }}
          exact
        />
        <Route
          path="/research-team/building-contacts/:buildingContactId/add-building"
          component={ResearchTeamBuildingContactAddBuilding}
          options={{ showHeader: false, showFooter: false }}
          exact
        />
        <Route
          path="/research-team/building-contacts/:buildingContactId/building-availability/:buildingId"
          component={ResearchTeamBuildingBuildingContactBuilding}
          options={{ showHeader: false, showFooter: false }}
          exact
        />
        <ProtectedRoute
          path="/research-team/building-contacts/:buildingContactId/building-availability/:buildingId/listings/:listingId"
          component={ResearchTeamBuildingBuildingContactBuildingEditListing}
          options={{ showHeader: false, showFooter: false }}
          check={isBroker}
          exact
        />
        <Route path="/commutes" component={CommuteCalculatorPage} />
        <Route
          path="/office-space-calculator"
          component={SpaceCalculatorPage}
        />
        <Route
          path="/profile"
          component={OnboardingPage}
          options={{ showHeader: false, showFooter: false }}
        />
        <Route path="/forgot-password" component={ForgotPasswordPage} />
        <Route path="/change-password" component={ResetPasswordPage} />
        <Route
          path="/confirm-account/:confirmationToken/:changeEmail?"
          component={AccountConfirmationPage}
        />
        <Route
          path="/sign-in"
          component={SignInPage}
          options={{ showHeader: true }}
        />
        <Route path="/sign-out" component={SignOutPage} />
        <Route path="/external_onboarding" component={ExternalOnboarding} />
        <Route
          path="/start/listing/:id"
          component={ClientSearchListingPage}
          options={{ showHeader: false, showFooter: false }}
          name="ClientSearchListingPage"
        />
        <Route
          path="/maintenance"
          exact
          component={MaintenancePage}
          options={{ showHeader: true, showFooter: true }}
        />

        <ProtectedRoute
          path="/tourbook/tours/:tourId"
          component={TourbookPage}
          signedOutLoader={InvitationPage}
          unauthorizedLoader={InvitationPage}
          options={tourbookRouteOptions}
        />
        <ProtectedRoute
          path="/tourbook/surveys/:surveyId"
          component={TourbookPage}
          signedOutLoader={PublicSurveyPage}
          unauthorizedLoader={PublicSurveyPage}
          options={tourbookRouteOptions}
        />

        <Route path="/joinSurvey/:surveyId" component={InvitationPage} />

        <ProtectedRoute
          path="/leasing-managers"
          component={LeasingManagerPage}
        />

        <ProtectedRoute
          path="/leasing-managers/new"
          component={LeasingManagerPage}
        />

        <ProtectedRoute
          path="/tourbook/:campaigns?/:campaignId?"
          component={TourbookPage}
          options={tourbookRouteOptions}
        />
        <Route
          path="/(tour-)?invitation/:invitationId"
          component={InvitationPage}
          options={tourbookRouteOptions}
        />
        <ProtectedRoute
          path="/building-admin"
          component={BuildingAdminPage}
          check={isAdmin}
        />
        <ProtectedRoute
          path="/user-admin"
          component={UserAdminPage}
          check={isAdmin}
        />
        <ProtectedRoute
          path="/featured-listings-admin"
          component={FeaturedListingsAdminPage}
          check={isAdmin}
        />

        <ProtectedRoute path="/settings" component={SettingsPage} />
        <ProtectedRoute path="/site-styles" component={StyleGuidePage} />
        <ProtectedRoute path="/:vendor/csv-upload" component={CSVUploadPage} />

        <ProtectedRoute
          path="/importer"
          component={ImporterPage}
          check={isAdmin}
        />

        <Route
          path="/search"
          exact
          component={PublicSearchPage}
          onEnter={replaceSearchURL}
          options={{ showOnboardingCTA: true }}
        />

        <Route
          name="BuildingPage"
          path={BUILDING_PATH_REGEX}
          component={BuildingPage}
          options={{ showOnboardingCTA: true }}
        />
        {/* Handle the new route, namespaced. Leaving the old one (above) in place and working, but since we've got an express redirect, it'll only exist to catch links we miss updating internally, making this a less fragile route change  */}
        <Route
          name="BuildingPage"
          path="/building/:state/:city/:address"
          component={BuildingPage}
          options={{ showOnboardingCTA: true }}
        />

        {/* Send legacy building and listing URLs to BuildingPage, which handles redirecting to canonicalized URLs */}
        <Route
          path="/buildings/:state([A-Za-z]{2})/:city/:zip(\d+)/:neighborhood(\D[^\/]+)/:address(\d+.+)"
          component={BuildingPage}
        />
        <Route
          path="/listings/:state([A-Za-z]{2})/:city/:zip(\d+)/:neighborhood(\D[^\/]+)/:address(\d+[^\/]+)/:floorAndSuite"
          component={BuildingPage}
        />
        <Route
          path="/buildings/:state([A-Za-z]{2})?/:city?/:neighborhood(\D[^\/]+)?/:page(\d+)?"
          component={SitemapPage}
        />

        <ProtectedRoute
          path="/listings/:listingId"
          exact
          component={LoggedInListingPage}
        />

        {/* 301s for inbound links which are currently 404ing */}
        <Redirect
          from="/new-york-city"
          to="/office-space/ny/new-york"
          statusCode={301}
        />
        <Redirect
          from="/houston"
          to="/office-space/tx/houston"
          statusCode={301}
        />
        <Redirect from="/landlords" to="/" statusCode={301} />

        <Redirect from="/gclid" to="/debuginfo" statusCode={302} />
        <Route path="/debuginfo" exact component={DebugInfoPage} />

        <ProtectedRoute
          path="/image-slideshow"
          component={ImageSlideshowPage}
          options={{ showHeader: false, showFooter: false }}
        />
        <Route
          path="/"
          exact
          component={HomePage}
          name="HomePage"
          options={{ showHeader: true, showFooter: true }}
        />
        <Route statusCode={404} component={Error404Page} />
      </Switch>
    </App>
  );
}
