import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { login } from 'actions/user';
import { updateSearch } from 'actions/search';
import LoadingDots from 'components/LoadingDots';
import SignInMutation from 'queries/mutations/SignInMutation.gql';
import H1 from 'components/deprecated/legacyElements/H1';
import H2 from 'components/deprecated/legacyElements/H2';
import SurveyVisitEmailMutation from 'queries/mutations/SurveyVisitEmailMutation.gql';
import CurrentUserQuery from 'queries/CurrentUserQuery.gql';
import { track, eventCategories, analyticsEvents } from 'util/analytics';

import Modal from '../SplitContentModal';
import styles from './style.module.scss';
import chairs from './chairs.jpg';

const SignInModal = ({
  customCTA,
  customSideContent,
  existingEmail,
  onClose,
  onSuccess,
  eventLabel,
  createAccountLink,
  onUserLoginChange,
  updateUserSearch,
}) => {
  const [email, setEmail] = useState(existingEmail);
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState(false);
  const [loggingIn, setLoggingIn] = useState(false);
  const [signIn] = useMutation(SignInMutation);
  const [onClientLoginNotifyBroker] = useMutation(SurveyVisitEmailMutation);
  const { client: apolloClient } = useQuery(CurrentUserQuery);

  useEffect(() => {
    track(analyticsEvents.open, {
      category: eventCategories.modal,
      label: eventLabel,
    });
  }, []);

  const trackClick = () =>
    track(analyticsEvents.forgot_password_link, {
      category: eventCategories.account,
      label: eventLabel,
    });

  const handleClose = (evt) => {
    evt.stopPropagation();
    track(analyticsEvents.close, {
      category: eventCategories.modal,
      label: `Sign In, ${eventLabel}`,
    });
    onClose();
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setLoggingIn(true);
    try {
      const { data } = await signIn({ variables: { email, password } });
      apolloClient.resetStore(); //kickoff a refetch of Observable queries, but don't worry about waiting for the promise to resolve. Props will change and react will re-render as necessary. (This is in part due to a bug somewhere that's causing this promise not to properly resolve)
      if (!data.signIn.currentUser) {
        setLoginError(true);
        setLoggingIn(false);
      } else {
        track(analyticsEvents.sign_in, {
          category: eventCategories.account,
          label: eventLabel,
        });
        onUserLoginChange(data.signIn.currentUser);
        if (data.signIn.currentUser.cityOfInterest) {
          updateUserSearch({
            numEmployees:
              data.signIn.currentUser.latestCampaign.currentActiveRequirements
                ?.numEmployees,
            neighborhoods:
              data.signIn.currentUser.latestCampaign.currentActiveRequirements?.neighborhoods?.map(
                ({ name }) => name
              ) || [],
            cityName: data.signIn.currentUser.cityOfInterest,
          });
        }
        if (!data.signIn.currentUser.isBroker) {
          onClientLoginNotifyBroker({
            variables: {
              campaignId: data.signIn.currentUser.latestCampaign.id,
            },
          });
        }
        if (onSuccess) {
          onSuccess(data.signIn.currentUser);
        } else {
          onClose();
        }
      }
    } catch {
      setLoginError(true);
      setLoggingIn(false);
    }
  };

  return (
    <Modal
      onClose={handleClose}
      sideContent={
        <span className={styles.sideContent}>
          {React.cloneElement(customSideContent, {
            className: styles.customSideContent,
          })}
        </span>
      }
    >
      <div className={styles.cta}>{customCTA}</div>
      <form className={styles.form} onSubmit={handleSubmit}>
        <input
          type="email"
          value={email}
          onChange={({ target: { value } }) => setEmail(value)}
          name="email"
          placeholder="Email"
          className={cn(styles.input, { [styles.inputError]: loginError })}
          data-qa="sign-in-modal-email"
          required
        />
        <input
          type="password"
          value={password}
          onChange={({ target: { value } }) => setPassword(value)}
          name="password"
          placeholder="Password"
          className={cn(styles.input, { [styles.inputError]: loginError })}
          data-qa="sign-in-modal-password"
          autoComplete="current-password"
          required
        />
        <Link
          className={styles.link}
          to="/forgot-password"
          target="_blank"
          onClick={trackClick}
        >
          Forgot Password?
        </Link>
        <p
          className={cn(styles.error, {
            [styles.visible]: loginError || existingEmail,
          })}
        >
          {loginError
            ? 'The email or password you entered is incorrect.'
            : 'You already have an account. Enter your password or click "Forget password?"'}
        </p>
        <button
          className={styles.button}
          data-qa="sign-in-button"
          disabled={!email || !password}
          type="submit"
        >
          {loggingIn ? (
            <>
              SIGNING IN
              <LoadingDots />
            </>
          ) : (
            <>SIGN IN</>
          )}
        </button>
      </form>
      {createAccountLink && (
        <div className={styles.createAccountLinkContainer}>
          <a href={createAccountLink}>Create Account</a>
        </div>
      )}
    </Modal>
  );
};

SignInModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onUserLoginChange: PropTypes.func.isRequired,
  updateUserSearch: PropTypes.func.isRequired,
  existingEmail: PropTypes.string,
  onSuccess: PropTypes.func,
  createAccountLink: PropTypes.string,
  customCTA: PropTypes.node, // custom HTML that is rendered above the form inputs.
  eventLabel: PropTypes.string, // additional analytics label.
  customSideContent: PropTypes.node, // customSideContent. Styles will have height: 100% and width: 100% applied to it.
};

SignInModal.defaultProps = {
  existingEmail: '',
  onSuccess: null,
  createAccountLink: null,
  customCTA: (
    <>
      <H1 modal className={styles.heading}>
        Welcome Back
      </H1>
      <H2 modal className={styles.subheading}>
        Sign in to proceed
      </H2>
    </>
  ),
  eventLabel: 'Sign In Modal',
  customSideContent: <img src={chairs} alt="A beautiful office" />,
};
export { SignInModal };
export default compose(
  connect(null, {
    onUserLoginChange: login,
    updateUserSearch: updateSearch,
  })
)(SignInModal);
