import { connect } from 'react-redux';
import {
  login,
  logout,
  updateUser,
  updateVisitorProfile,
  updateDisplayName,
} from 'actions/user';

export default connect(
  (state) => ({
    currentUser: state.user.currentUser,
    numberOfInteractions: state.user.numberOfInteractions,
  }),
  {
    login,
    logout,
    updateUser,
    updateVisitorProfile,
    updateDisplayName,
  }
);
