import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const LegacyH2 = ({
  bold,
  modal,
  footer,
  className,
  children,
  ...restProps
}) => {
  const classNames = cn(
    !modal && !footer && styles.h2,
    bold && styles.bold,
    //h2 styles for modals
    modal && styles.modal,
    className
  );

  return (
    <h2 {...restProps} className={classNames}>
      {children}
    </h2>
  );
};

LegacyH2.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  modal: PropTypes.bool,
  bold: PropTypes.bool,
  footer: PropTypes.bool,
};

LegacyH2.defaultProps = {
  className: '',
  children: null,
  modal: false,
  bold: false,
  footer: false,
};

export default LegacyH2;
