/* eslint-disable no-console */
import { ApolloLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';

const TIMEOUT_MAX_RETRIES = 3;
const TIMEOUT_MESSAGE_REGEX = /^Timeout/; // error from server
const TIMEOUT_ERROR_MESSAGE = 'GraphQL Server timeout';

// upgrade GraphQL Timeout errors to JS errors so RetryLink catches them
const upgradeTimeoutErrorLink = new ApolloLink((operation, forward) =>
  forward(operation).map((data) => {
    if (
      data &&
      data.errors &&
      data.errors.length > 0 &&
      data.errors.every((err) => err.message.match(TIMEOUT_MESSAGE_REGEX)) &&
      !operation.query.definitions.some((def) => def.operation === 'mutation')
    ) {
      throw new Error(TIMEOUT_ERROR_MESSAGE);
    }
    return data;
  })
);

// retry when receiving a GraphQL server timeout
const retryLink = new RetryLink({
  delay: { initial: 100 },
  attempts: (count, operation, error) => {
    if (
      count < TIMEOUT_MAX_RETRIES &&
      error.message === TIMEOUT_ERROR_MESSAGE
    ) {
      console.warn(
        `[GraphQL timeout in "${operation.operationName}"]: ${TIMEOUT_ERROR_MESSAGE}, retrying...`
      );
      return true;
    }
    return false;
  },
});

// report all errors received by Apollo to the console
const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    console.error(
      `[GraphQL error${graphQLErrors.length > 1 ? 's' : ''} in "${
        operation.operationName
      }"]: ` +
        graphQLErrors
          .map(
            ({ message, path }) => `${message}${path ? ` (Path: ${path})` : ''}`
          )
          .join('\n\t')
    );
  }
  if (networkError) {
    console.error(
      `[GraphQL network error in "${operation.operationName}"]: ${networkError}`
    );
  }
});

const combinedLink = errorLink.concat(
  retryLink.concat(upgradeTimeoutErrorLink)
);
export default combinedLink;
