import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './styles.module.scss';

const Display = (props) => (
  <span {...props} className={cn(styles.display, props.className)} />
);

Display.propTypes = {
  className: PropTypes.string,
};

Display.defaultProps = {
  className: '',
};

export default Display;
