import networkRequestStatuses from 'enums/networkRequestStatuses';

const initialFormData = {
  text: { value: '' },
  file: { value: null },
  requestStatus: networkRequestStatuses.idle,
};

const initialState = {
  addNoteFormData: initialFormData,
  editNoteFormData: initialFormData,
  editingNoteId: '',
};

export const updateAddSharedNoteForm = (payload) => ({
  type: 'SHARED_NOTES/UPDATE_ADD_FORM',
  payload,
});

export const updateEditSharedNoteForm = (payload) => ({
  type: 'SHARED_NOTES/UPDATE_EDIT_FORM',
  payload,
});

export const selectEditableSharedNote = (payload) => ({
  type: 'SHARED_NOTES/SELECT_EDITABLE_NOTE',
  payload,
});

export const createdSharedNotePending = () => ({
  type: 'SHARED_NOTES/CREATE_PENDING',
});

export const createdSharedNoteSuccess = () => ({
  type: 'SHARED_NOTES/CREATE_SUCCESS',
});

export const createdSharedNoteFailure = () => ({
  type: 'SHARED_NOTES/CREATE_FAILURE',
});

export const editedSharedNotePending = () => ({
  type: 'SHARED_NOTES/EDIT_PENDING',
});

export const editedSharedNoteSuccess = () => ({
  type: 'SHARED_NOTES/EDIT_SUCCESS',
});

export const editedSharedNoteFailure = () => ({
  type: 'SHARED_NOTES/EDIT_FAILURE',
});

export const resetAddSharedNoteForm = () => ({
  type: 'SHARED_NOTES/RESET_ADD_FORM',
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'SHARED_NOTES/UPDATE_ADD_FORM': {
      const { fieldName, fieldValue } = action.payload;
      const addNoteFormData = {
        ...state.addNoteFormData,
        [fieldName]: { value: fieldValue },
      };

      return { ...state, addNoteFormData };
    }
    case 'SHARED_NOTES/UPDATE_EDIT_FORM': {
      const { fieldName, fieldValue } = action.payload;
      const editNoteFormData = {
        ...state.editNoteFormData,
        [fieldName]: { value: fieldValue },
      };

      return { ...state, editNoteFormData };
    }
    case 'SHARED_NOTES/CREATE_PENDING': {
      const addNoteFormData = {
        ...state.addNoteFormData,
        requestStatus: networkRequestStatuses.pending,
      };
      return { ...state, addNoteFormData };
    }
    case 'SHARED_NOTES/CREATE_SUCCESS': {
      const addNoteFormData = {
        ...initialFormData,
        requestStatus: networkRequestStatuses.success,
      };
      return { ...state, addNoteFormData };
    }
    case 'SHARED_NOTES/CREATE_FAILURE': {
      const addNoteFormData = {
        ...state.addNoteFormData,
        requestStatus: networkRequestStatuses.failure,
      };
      return { ...state, addNoteFormData };
    }
    case 'SHARED_NOTES/EDIT_PENDING': {
      const editNoteFormData = {
        ...state.editNoteFormData,
        requestStatus: networkRequestStatuses.pending,
      };
      return { ...state, editNoteFormData };
    }
    case 'SHARED_NOTES/EDIT_SUCCESS': {
      const editNoteFormData = {
        ...initialFormData,
        requestStatus: networkRequestStatuses.success,
      };
      return { ...state, editNoteFormData, editingNoteId: '' };
    }
    case 'SHARED_NOTES/EDIT_FAILURE': {
      const editNoteFormData = {
        ...state.editNoteFormData,
        requestStatus: networkRequestStatuses.failure,
      };
      return { ...state, editNoteFormData };
    }
    case 'SHARED_NOTES/RESET_ADD_FORM':
      return { ...state, addNoteFormData: initialFormData };
    case 'SHARED_NOTES/SELECT_EDITABLE_NOTE': {
      const { sharedNote } = action.payload;
      if (sharedNote === null) return { ...state, editingNoteId: '' };

      const editNoteFormData = {
        ...state.editNoteFormData,
        text: { value: sharedNote.text },
        requestStatus: networkRequestStatuses.idle,
      };
      return { ...state, editNoteFormData, editingNoteId: sharedNote.id };
    }
    case 'LISTINGS/SELECT':
      return initialState;
    default:
      return state;
  }
}
