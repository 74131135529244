// TODO: Remove this whole file when `apollo-link-batch-http` interface is updated
// Update: We've migrated to the built-in batch-http method but didn't look into extracting this component yet
import { BatchHttpLink } from '@apollo/client/link/batch-http';

export default function createBatchingHttpLink(args = {}) {
  const { uri, ...fetchOptions } = args;
  const headers = {
    Accept: '*/*',
    'Content-Type': 'application/json',
    ...(args.headers || {}),
  };

  return new BatchHttpLink({
    uri,
    fetchOptions,
    headers,
    batchMax: 2000,
  });
}
