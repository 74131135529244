import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { compose } from 'redux';

import { isBroker } from 'domain/user';
import withUser from 'components/withUser';
import SignInModal from 'components/ClientSearch/Modal/NewSignInModal';
import Button from 'components/deprecated/legacyElements/Button';
import H1 from 'components/deprecated/legacyElements/H1';

import styles from './style.module.scss';

class Header extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    currentUser: PropTypes.object,
    activeCampaign: PropTypes.object,
  };

  static defaultProps = {
    className: null,
    currentUser: null,
    activeCampaign: null,
  };

  state = {
    showSignInModal: false,
  };

  componentDidUpdate() {
    if (!this.props.currentUser) {
      document.getElementById('toggle-header-menu').checked = false;
    }
  }

  openSignInModal = () => this.setState({ showSignInModal: true });

  closeSignInModal = () => this.setState({ showSignInModal: false });

  onItemClicked = () => {
    document.getElementById('toggle-header-menu').checked = false;
  };

  render() {
    const showCampaignHeader =
      this.props.currentUser &&
      this.props.currentUser.isBroker &&
      this.props.activeCampaign &&
      typeof window !== 'undefined' &&
      window.location.pathname.match(/^\/tourbook\//);
    return (
      <header
        className={`inset-layout ${styles.container} ${this.props.className}`}
      >
        <div className={styles.logoContainer}>
          <Link to={showCampaignHeader ? '/tourbook/campaigns' : '/'}>
            <img
              src={require('assets/images/brand-mark.svg')}
              alt="brand"
              className={styles.logo}
            />
            {!showCampaignHeader && (
              <img
                src={require('assets/images/brand-text.svg')}
                alt="squarefoot"
                title="SquareFoot"
                className={styles.logoText}
              />
            )}
          </Link>
          {showCampaignHeader && (
            <Link to={`/tourbook/campaigns/${this.props.activeCampaign.uuid}`}>
              <H1>{this.props.activeCampaign.displayName}&rsquo;s Campaign</H1>
            </Link>
          )}
        </div>
        <div className={styles.navigationContainer}>
          <input
            type="checkbox"
            id="toggle-header-menu"
            className={styles.toggleButton}
          />
          <a href="tel:+19179941062" className={styles.phoneButton}>
            <span className="icon-phone" />
          </a>
          {!this.props.currentUser && (
            <label className={styles.burgerButton} htmlFor="toggle-header-menu">
              ☰
            </label>
          )}
          {!this.props.currentUser && (
            <label className={styles.closeButton} htmlFor="toggle-header-menu">
              ✕
            </label>
          )}

          <nav className={styles.nav}>
            {this.props.currentUser && (
              <ul>
                <li>
                  <Link to="/settings" style={{ padding: 0 }}>
                    {this.props.currentUser.displayName}
                  </Link>
                </li>
                {!isBroker && (
                  <li className={styles.contact}>
                    <a href="tel:+19179941062">
                      <span className="icon-phone" />
                      <span>(917) 994-1062</span>
                    </a>
                  </li>
                )}
              </ul>
            )}
            {!this.props.currentUser && (
              <ul>
                <li className={styles.menuItem}>
                  <NavLink
                    to="/office-space-calculator"
                    onClick={this.onItemClicked}
                  >
                    Smart Tools
                  </NavLink>
                </li>
                <li className={styles.menuItem}>
                  <Button onClick={this.openSignInModal}>Sign In</Button>
                </li>
                <li className={styles.contact}>
                  <a href="tel:+19179941062">
                    <span className="icon-phone" />
                    <span>(917) 994-1062</span>
                  </a>
                </li>
                <li className={styles.menuItem}>
                  <a
                    href="/leasopedia/#office-search-guide"
                    onClick={this.onItemClicked}
                    className={styles.quickStart}
                  >
                    Quick Start Guide
                  </a>
                </li>
              </ul>
            )}
          </nav>
          {this.props.currentUser && (
            <Link to="/settings" className={styles.cogButton}>
              <span className="icon-cog" />
            </Link>
          )}
        </div>
        {this.state.showSignInModal && (
          <SignInModal onClose={this.closeSignInModal} />
        )}
      </header>
    );
  }
}

export default compose(
  withUser,
  connect((state) => ({ activeCampaign: state.tourbook.activeCampaign }))
)(Header);
