import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const LegacyButton = ({ 'data-qa': dataQa, ...props }) => {
  const classNames = cn(
    styles.button,
    props.secondary && styles.secondary,
    props.tertiary && styles.tertiary,
    props.outline && styles.outline,
    props.small && styles.small,
    props.cancel && styles.cancel,
    props.inline && styles.inline,
    props.disabled && styles.disabled,
    props.className
  );

  // destructure out props like "secondary" and "tertiary" that we dont want to pass on to the native element.
  const {
    secondary,
    tertiary,
    outline,
    small,
    cancel,
    inline,
    className,
    type,
    ...otherProps
  } = props;

  return (
    // One way or another this next line is going to violate a linter rule, but at least we're now guaranteed to have a type for the button
    // (Or a console error)
    // eslint-disable-next-line react/button-has-type
    <button data-qa={dataQa} {...otherProps} className={classNames} type={type}>
      {props.children}
    </button>
  );
};

LegacyButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  secondary: PropTypes.bool,
  tertiary: PropTypes.bool,
  outline: PropTypes.bool,
  small: PropTypes.bool,
  cancel: PropTypes.bool,
  inline: PropTypes.bool,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  'data-qa': PropTypes.string,
};

LegacyButton.defaultProps = {
  className: '',
  children: null,
  secondary: false,
  tertiary: false,
  outline: false,
  small: false,
  cancel: false,
  inline: false,
  disabled: false,
  type: 'button',
  'data-qa': 'legacy-button',
};

export default LegacyButton;
