import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { hideToast } from 'actions/toast';
import cn from 'classnames';
import styles from './style.module.scss';

const Toast = (props) => {
  return (
    <div className={styles.container} data-qa="toast">
      <div
        className={cn(styles.inner, {
          [styles.visible]: props.visible,
          [styles.hidden]: !props.visible,
          [styles.errorMode]: props.isError,
        })}
      >
        <div
          className={styles.closeButton}
          onClick={props.hideToast}
          role="button"
          tabIndex={0}
          onKeyPress={props.hideToast}
        >
          <span className="icon icon-cross" />
        </div>
        {props.contents}
      </div>
    </div>
  );
};

Toast.propTypes = {
  contents: PropTypes.any,
  hideToast: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  isError: PropTypes.bool,
};

Toast.defaultProps = {
  isError: false,
  contents: null,
  visible: false,
};

export default connect(
  (state) => ({
    visible: state.toast.visible,
    contents: state.toast.contents,
    isError: state.toast.isError,
  }),
  {
    hideToast,
  }
)(Toast);
