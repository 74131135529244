import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';
import styles from './style.module.scss';

function Spinner({ floating, size, className, style, highContrast }) {
  return (
    <div
      className={`${styles.container} ${floating ? styles.floating : ''} ${
        className || ''
      }`}
      style={style}
    >
      <svg
        className={styles.spinner}
        viewBox="0 0 22 22"
        width={size}
        height={size}
      >
        <defs>
          <mask id="logo-clip">
            <rect x="0" y="0" width="100%" height="100%" fill="white" />
            <rect x="0" y="11.82" width="3.1" height="6.18" fill="black" />
            <rect x="18.9" y="4.78" width="3.1" height="6.27" fill="black" />
          </mask>
          <path
            id="square"
            strokeWidth="3"
            fill="none"
            d="M1.5,15 1.5,1.5 20.5,1.5 20.5,20.5 1.5,20.5 1.5,15"
            mask="url(#logo-clip)"
          />
        </defs>
        <g className={styles.bg}>
          <use xlinkHref="#square" />
        </g>
        <g className={cn(styles.fg, highContrast && styles.highContrast)}>
          <use xlinkHref="#square" />
        </g>
      </svg>
    </div>
  );
}

Spinner.propTypes = {
  floating: PropTypes.bool,
  size: PropTypes.number,
  className: PropTypes.string,
  style: PropTypes.string,
  highContrast: PropTypes.bool,
};

Spinner.defaultProps = {
  floating: false,
  size: 22,
  className: '',
  style: null,
  highContrast: false,
};

export default Spinner;
