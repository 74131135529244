import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const LegacyH1 = (props) => {
  const classNames = cn(
    styles.h1,
    //"display" is a permutation of the base h1
    props.display && styles.display,
    //"headline" is a permutation of the base h1
    props.headline && styles.headline,
    //inset are styles that previous applied to DIRECT descendants of the .inset and .inset-layout global classes. (which may now be removed). This class centers the heading.
    props.inset && styles.inset,
    //h1s that weren't children of a `main` tag didn't have certain styles applied to them. These were all contained in modals, hence the name.
    props.modal ? '' : styles.main,
    props.className
  );

  // destructure out props like "display" that we dont want to pass on to the native element.
  const { display, headline, inset, modal, ...otherProps } = props;

  return (
    <h1 {...otherProps} className={classNames}>
      {props.children}
    </h1>
  );
};

export default LegacyH1;

LegacyH1.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  display: PropTypes.bool,
  headline: PropTypes.bool,
  inset: PropTypes.bool,
  modal: PropTypes.bool,
};

LegacyH1.defaultProps = {
  className: '',
  children: null,
  display: false,
  headline: false,
  inset: false,
  modal: false,
};
