import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import cn from 'classnames';
import Button from 'components/deprecated/legacyElements/Button';
import styles from './style.module.scss';

export default class Modal extends Component {
  constructor(props) {
    super(props);
    // Only runs if we're not prerendering
    if (typeof window !== 'undefined')
      this.container = document.createElement('div');
  }

  componentDidMount() {
    this.openPortal(this.props);
  }

  componentWillUnmount() {
    this.closePortal();
  }

  openPortal(props) {
    // Only runs if we're not prerendering
    if (typeof window !== 'undefined') {
      this.container.className = `${styles.container} ${
        props.containerClassName || ''
      }`;
      this.container.id = 'modal-portal';
      if (document.body) document.body.appendChild(this.container);
      if (!props.mousetrap)
        this.container.addEventListener('mousedown', this.onClick, false);
      if (typeof window !== 'undefined')
        window.addEventListener('keydown', this.onKeyDown);
    }
  }

  closePortal() {
    // Only runs if we're not prerendering
    if (typeof window !== 'undefined') {
      if (!this.props.mousetrap)
        this.container.removeEventListener('mousedown', this.onClick, false);
      if (typeof window !== 'undefined')
        window.removeEventListener('keydown', this.onKeyDown);
      if (document.body) document.body.removeChild(this.container);
      delete this.container;
    }
  }

  onClick = (evt) => {
    let node = evt.target;
    if (!this.contentNode) return;
    while (node) {
      if (node.parentNode && node.parentNode.id === 'modal-portal') return;
      if (node === this.contentNode) return;
      node = node.parentNode;
    }
    // if this component wasn't found in the event target's hierarchy then it was a click out
    evt.stopPropagation();
    this.props.onClose(evt);
  };

  onCloseButtonClick = (evt) => {
    evt.stopPropagation();
    this.props.onClose(evt);
  };

  onKeyDown = (evt) => {
    if (this.props.onKeyDown) this.props.onKeyDown(evt);
    if (evt.key === 'Escape' && this.props.onClose) {
      this.props.onClose();
      evt.stopPropagation();
      evt.preventDefault();
    }
  };

  render() {
    const { className, mousetrap, children } = this.props;
    const innerModalStyles = cn(
      this.props.basicStyles ? styles.basicInner : styles.inner,
      className
    );

    if (!this.container) return null;

    return ReactDOM.createPortal(
      <div
        data-qa="modal"
        className={innerModalStyles}
        ref={(elem) => {
          this.contentNode = elem;
        }}
      >
        {!mousetrap && (
          <Button
            onClick={this.onCloseButtonClick}
            className={styles.closeButton}
          >
            &#215;
          </Button>
        )}
        {children}
      </div>,
      this.container
    );
  }
}

Modal.propTypes = {
  // Removes the more 'opinionated' styles of the modal, including a bunch of
  // styles applied to child elements, like forms, headers, etc. Useful if
  // these styles are in conflict with newer designs.
  basicStyles: PropTypes.bool,
};

Modal.defaultProps = {
  basicStyles: false,
};
