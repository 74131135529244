import React from 'react';
import { push } from 'react-router-redux';
import airbrake from 'util/airbrake';
import find from 'lodash/find';
import { LEASE_TERMS, MOVE_IN_DATES } from 'util/constants';

const NYMarket = {
  id: '1612',
  name: 'New York',
  externalId: '635d40e4-ee2c-4144-8c95-040714888811',
  position: {
    lat: 40.712784,
    lng: -74.005941,
  },
  __typename: 'Market',
};

export const initialState = {
  lastActiveScreenIndex: 0,

  city: 'New York',
  numEmployees: undefined,
  psfPerEmployee: 150,
  totalSquareFeet: 0,
  budgetPsf: 50.0,
  budgetMonthly: 12500,
  leaseTerm: 'THREE_TO_FIVE_YEARS',
  moveInDate: 'FLEXIBLE',
  name: '',
  companyName: '',
  email: '',
  phone: '',
  renterComments: '',
  phase: 'NOT_STARTED',
  onboardingId: '',
  sizeError: undefined,
  areasOfInterest: [NYMarket],
};

export const locationBlurb = ({ areasOfInterest }) => {
  const market = find(areasOfInterest, { __typename: 'Market' });

  if (!market) {
    airbrake.notify('Market could not be found in areasOfInterest');
    return '';
  }
  const neighborhoods = areasOfInterest.filter(
    ({ __typename }) => __typename === 'Neighborhood'
  );
  let blurb = `${market.name}`;
  if (neighborhoods.length >= 0)
    blurb += `: ${neighborhoods.map(({ name }) => name).join(', ')}`;
  return blurb;
};

export const screens = [
  {
    name: 'Size',
    link: '/profile/size',
    blurb: (state) =>
      `${state.numEmployees} seats, ~${state.totalSquareFeet} sf`,
    canContinue: () => true,
  },
  {
    name: 'Location',
    link: '/profile/location',
    blurb: locationBlurb,
    canContinue: (state) => !!state.city,
  },
  {
    name: 'Budget',
    link: '/profile/budget',
    blurb: (state) =>
      state.budgetPsf
        ? `$${state.budgetPsf} psf / $${state.budgetMonthly} monthly`
        : 'Flexible',
    canSkip: true,
  },
  {
    name: 'Timeframes',
    link: '/profile/timeframes',
    blurb: (state) => (
      <span>
        Lease: {LEASE_TERMS[state.leaseTerm]}
        <br />
        Move-in: {MOVE_IN_DATES[state.moveInDate]}
      </span>
    ),
    canContinue: (state) => !!(state.leaseTerm && state.moveInDate),
  },
  {
    name: 'Results',
    link: '/profile/results',
  },
];

export const update = (payload) => ({ type: 'ONBOARDING/UPDATE', payload });
export const displaySizeError = (payload) => ({
  type: 'ONBOARDING/SIZE_ERROR',
  payload,
});
export const updatePhase = (phase) => ({
  type: 'ONBOARDING/UPDATE_PHASE',
  phase,
});
export const nextPage = () => (dispatch, getState) => {
  const state = getState();
  const activeScreenIndex = screens.findIndex(
    (screen) => screen.link === state.routing.location.pathname
  );
  let nextScreen = screens[activeScreenIndex + 1];
  if (activeScreenIndex > -1) {
    dispatch({
      type: 'ONBOARDING/NEXT_PAGE',
      nextScreenIndex: activeScreenIndex + 1,
    });
  } else {
    // We're re-entering the onboarding flow from a non-onboarding page,
    // send user to the last page they were on
    nextScreen = screens[state.onboarding.lastActiveScreenIndex];
  }
  if (nextScreen) dispatch(push(nextScreen.link));
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'ONBOARDING/SIZE_ERROR':
      return {
        ...state,
        sizeError: 'Please enter a value to continue.',
      };

    case 'ONBOARDING/NEXT_PAGE': {
      // Move to IN_PROGRESS phase if applicable
      const nextPhase =
        state.phase !== 'COMPLETED' ? 'IN_PROGRESS' : state.phase;
      return {
        ...state,
        sizeError: undefined,
        phase: nextPhase,
        lastActiveScreenIndex: Math.min(
          screens.length - 1,
          Math.max(state.lastActiveScreenIndex, action.nextScreenIndex)
        ),
      };
    }

    case 'ONBOARDING/UPDATE_PHASE':
      return {
        ...state,
        phase: action.phase,
      };

    case 'ONBOARDING/UPDATE': {
      const { numEmployees } = action.payload;
      const psfPerEmployee =
        action.payload.psfPerEmployee || state.psfPerEmployee;
      let totalSquareFeet =
        action.payload.totalSquareFeet || state.totalSquareFeet;
      let budgetMonthly = action.payload.budgetMonthly || state.budgetMonthly;
      let { budgetPsf } = state; // default to current state
      if (
        action.payload.budgetPsf ||
        ['', 0].includes(action.payload.budgetPsf)
      ) {
        // if budgetPsf payload is provided or a falsy value
        budgetPsf = action.payload.budgetPsf;
      }

      if (action.payload.numEmployees || action.payload.psfPerEmployee) {
        // Update square feet based on number of employees / size per employee
        totalSquareFeet = numEmployees * psfPerEmployee;
      }

      if (action.payload.areasOfInterest) {
        /* Catch the missing __typename error when the false data is added to the redux store */
        const market = find(action.payload.areasOfInterest, {
          __typename: 'Market',
        });
        if (!market) {
          airbrake.notify('Market is missing from areasOfInterest');
          return { ...state, ...action.payload, areasOfInterest: [NYMarket] };
        }
        // Calculate new budget based on neighborhood averages, filtered unknown vales and default to state if all are unknown
        const nonZeroYearlyPsfNeighborhoods = action.payload.areasOfInterest
          .filter(({ __typename }) => __typename === 'Neighborhood')
          .map((n) => n.yearlyPricePerFoot)
          .filter(Boolean);
        budgetPsf =
          nonZeroYearlyPsfNeighborhoods.reduce(
            (totalYearlyPsf, yearlyPsf) => totalYearlyPsf + yearlyPsf,
            0
          ) / nonZeroYearlyPsfNeighborhoods.length || state.budgetPsf;
      } else if (action.payload.budgetPsf) {
        // Update monthly budget based on user-set PSF
        budgetMonthly = (action.payload.budgetPsf * totalSquareFeet) / 12;
      } else if (action.payload.budgetMonthly) {
        // Update PSF based on user-set monthly budget
        budgetPsf = (12 * action.payload.budgetMonthly) / totalSquareFeet;
      }

      // Recalculate monthly budget unless explicitly set
      if (!action.payload.budgetMonthly) {
        budgetMonthly = (budgetPsf * totalSquareFeet) / 12;
      }

      return {
        ...state,
        ...action.payload,
        budgetPsf: Math.round(budgetPsf) || 0,
        budgetMonthly: Math.ceil(budgetMonthly) || 0,
        sizeError: undefined,
        totalSquareFeet,
        numEmployees:
          numEmployees !== undefined ? numEmployees : state.numEmployees,
        psfPerEmployee,
      };
    }

    case '@@router/LOCATION_CHANGE': {
      if (action.payload.query && action.payload.query.city) {
        return {
          ...state,
          city: action.payload.query.city,
        };
      }
      return state;
    }
    case 'persist/REHYDRATE': {
      // don't rehydrate onboarding city if query param is provided
      if (
        action.payload.onboarding &&
        action.payload.onboarding.city &&
        typeof window !== 'undefined'
      ) {
        const cityParam = window.location.search.match(/[?&]city=([^&]+)/);
        if (cityParam && cityParam[1] !== action.payload.onboarding.city) {
          return {
            ...state,
            city: cityParam[1],
          };
        }
      }

      // if market does not exist in state then default to nyc
      const market = find(state?.areasOfInterest || [], {
        __typename: 'Market',
      });
      if (!market) {
        return {
          ...state,
          areasOfInterest: [NYMarket],
        };
      }
      return state;
    }

    default:
      return state;
  }
}
