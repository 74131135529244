import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';

export class MarkAnalyticsUserType extends Component {
  componentDidMount() {
    this.handleMarking();
  }

  componentDidUpdate() {
    this.handleMarking();
  }

  handleMarking() {
    const internalUser =
      this.props.localStorage.getItem('internalUser') ||
      this.props.queryString.includes('current_site') ||
      this.props.queryString.includes('snapshot_site') ||
      this.props.queryString.includes('next') ||
      this.props.userEmail.includes('@squarefoot') ||
      this.props.isBroker ||
      this.props.isAdmin;

    if (internalUser && !this.props.localStorage.getItem('internalUser')) {
      this.props.localStorage.setItem('internalUser', true);
    }

    if (!window.analytics) return;

    window.analytics.ready(() => {
      if (
        internalUser &&
        window.analytics.user().traits().userType !== 'internal'
      ) {
        window.analytics.identify({ userType: 'internal' });
      } else if (window.analytics.user().traits().userType === undefined) {
        window.analytics.identify({ userType: 'external' });
      }
    });
  }

  render = () => null;
}

MarkAnalyticsUserType.propTypes = {
  userEmail: PropTypes.string.isRequired,
  queryString: PropTypes.string.isRequired,
  localStorage: PropTypes.shape({
    getItem: PropTypes.func.isRequired,
    setItem: PropTypes.func.isRequired,
  }),
};

export default connect(
  (state) => ({
    userEmail: get(state, ['user', 'currentUser', 'email'], ''),
    isBroker: get(state, ['user', 'currentUser', 'isBroker'], false),
    isAdmin: get(state, ['user', 'currentUser', 'isAdmin'], false),
    queryString: get(window, ['location', 'search'], ''),
    localStorage: window.localStorage,
  }),
  {}
)(MarkAnalyticsUserType);
