import { matchPath } from 'react-router';

import { getSlugFromCityName } from 'util/cities';
import { titleize, splitQueryString } from 'util/strings';
import {
  BUILDING_PATH_REGEX,
  OFFICE_SPACE_PATH_REGEX,
  PROPERTY_TYPES,
} from 'util/constants';

const defaultState = {
  cityName: 'New York',
  neighborhoods: [],
  numEmployees: null,
  offset: 0,
  limit: 12,
  updated: false,
};

// Converts raw request from React Router into search state
export const getStateFromRouter = (routerState) => {
  let params = routerState.params || {};
  const query =
    routerState.query ||
    (routerState.search ? splitQueryString(routerState.search) : {});

  if (routerState.pathname && !routerState.params) {
    const pathParts = routerState.pathname
      .split('/')
      .filter((part) => part && part !== 'map');
    const [propertyType, state, city, neighborhood] = pathParts;
    if (state && state.length === 2) {
      params = {
        state,
        city,
        neighborhood:
          PROPERTY_TYPES.indexOf(neighborhood) === -1
            ? neighborhood
            : undefined,
        propertyType,
      };
    }
  } else if (params && PROPERTY_TYPES.indexOf(params.slugPart1) === -1) {
    params.neighborhood = params.slugPart1;
  }

  return {
    cityName:
      query.city ||
      (params.city &&
        params.city.replace(/(^[a-z]|-[a-z])/g, (chr) =>
          chr.replace('-', ' ').toUpperCase()
        )) ||
      'New York',
    numEmployees: query.numEmployees || defaultState.numEmployees,
    offset:
      (query.page_number ? query.page_number - 1 : 0) *
      (query.limit || defaultState.limit),
    neighborhoods: (params.neighborhood || query.neighborhoods || '')
      .split(',')
      .filter((n) => n)
      .map((n) => titleize(n.replace('-', ' '))),
  };
};

// Converts search state into a React Router location descriptor
export function getLocationFromState(
  state,
  basePath = '/office-space/ny/new-york'
) {
  const query = {};

  // The base pathname comes from the city
  let pathname = '/office-space/ny/new-york';
  if (state.cityName && getSlugFromCityName(state.cityName)) {
    pathname = getSlugFromCityName(state.cityName);
  } else {
    // extract just the first two URL parts from the base path
    const slugMatch = basePath.match(/^\/office-space(\/..\/[^/]+)/);
    if (slugMatch) [, pathname] = slugMatch;
  }

  if (state.neighborhoods && state.neighborhoods.length === 1)
    pathname +=
      '/' + state.neighborhoods[0].replace(/[\s-/]/g, '-').toLowerCase();
  if (state.neighborhoods && state.neighborhoods.length > 1)
    query.neighborhoods = state.neighborhoods.join(',');
  if (state.numEmployees && state.numEmployees !== defaultState.numEmployees)
    query.numEmployees = state.numEmployees;
  if (state.offset && state.offset > 0)
    query.page_number = Math.ceil(state.offset / (state.limit || 1)) + 1;

  // Encode the query into a parameter string
  const search = Object.keys(query)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`
    )
    .join('&');

  return {
    pathname: `/office-space${pathname
      .toString()
      .replace(/\/[a-z]+-space/gi, '')}`,
    search: search ? `?${search}` : '',
    query,
  };
}

// This is used by the /search route, which takes params and generates a canonical URL
export function replaceSearchURL(routerState, replace) {
  const nextState = getStateFromRouter(routerState);
  const nextLocation = getLocationFromState(nextState);
  replace(nextLocation);
}

export const updateSearch = (payload, updateRoute) => (dispatch, getState) => {
  if (payload) dispatch({ type: 'SEARCH/UPDATE', payload, track: true });
  if (updateRoute) updateRoute(getLocationFromState(getState().search));
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case 'SEARCH/UPDATE': {
      const nextState = { ...state, ...action.payload };
      // if city is updated, remove any selected neighborhoods
      if (
        nextState.cityName !== state.cityName &&
        !action.payload.neighborhoods
      ) {
        nextState.neighborhoods = [];
      }
      // if search params are updated, reset pagination
      if (
        !action.payload.offset &&
        (nextState.cityName !== state.cityName ||
          nextState.neighborhoods !== state.neighborhoods ||
          nextState.numEmployees !== state.numEmployees)
      ) {
        nextState.offset = 0;
      }
      // this bool is used to determine if the state hasn't been changed from default
      nextState.updated = true;
      return nextState;
    }
    case '@@router/LOCATION_CHANGE': {
      // Handle page updates from internal navigation, which are from <Links> so cannot call updateSearch
      if (
        matchPath(action.payload.pathname, OFFICE_SPACE_PATH_REGEX) &&
        !matchPath(action.payload.pathname, BUILDING_PATH_REGEX)
      ) {
        const { neighborhoods, offset, cityName } = getStateFromRouter(
          action.payload
        );
        return { ...state, neighborhoods, offset, cityName };
      }
      return state;
    }
    default:
      return state;
  }
}
