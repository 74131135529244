import { getCookie } from './cookies';

// Names of analytics events sent to segment
export const analyticsEvents = {
  skip: 'Skip',
  duplicate_renter_signup: 'duplicate_renter_signup',
  home_view: 'home_view',
  search_view: 'search_view',
  building_view: 'building_view',
  ppc_modal_form_view: 'ppc_modal_form_view',
  ppc_form_submission: 'ppc_form_submission',
  sign_up: 'sign_up',
  expand_listing: 'expand_listing',
  inquiry_button_click: 'inquiry_modal_click',
  inquiry_modal_form_view: 'inquiry_modal_form_view',
  show_tour_modal: 'show_tour_modal',
  login: 'login',
  request_appointment: 'request_appointment',
  client_search_listings_drawer_view: 'client_search_listings_drawer_view',
  recommended_listings_save: 'recommended_listings_save',
  recommended_listings_pass: 'recommended_listings_pass',
  recommended_listings_viewed: 'recommended_listings_viewed',
  recommended_tour_accept: 'recommended_tour_accept',
  recommended_tour_decline: 'recommended_tour_decline',
  client_search_listing_save: 'client_search_list_save',
  client_search_drawer_save: 'client_search_drawer_save',
  client_search_other_availability_save:
    'client_search_other_availability_save',
  virtual_tour_start: 'start_virtual_tour',
  save_listing: 'Save Listing',
  request_tour: 'Request Tour',
  close: 'Close',
  open: 'Open',
  sign_in_link: 'Sign In Link',
  view_listings: 'view_listings',
  listing_image_viewed: 'listing_image_viewed',
  modal_sign_up: 'Sign Up',
  all_listing_images_viewed: 'All Listing Images Viewed',
  sign_in: 'Sign In',
  forgot_password_link: 'Forgot Password Link',
  apply_filters: 'Apply Filters',
  logged_out_protected_route: 'Logged Out Protected Route',
  view_case_study: 'View Case Study',
  shared_space_inquiry: 'Shared Space Inquiry',
  pivot_desk_listing_inquiry: 'PivotDesk listing inquiry',
  zero_state_viewed: 'Zero State Viewed',
  geolocate: 'Geolocation',
  search_results: 'Search Results',
  share_survey: 'Share Survey',
  search: 'Search',
};

export const eventCategories = {
  inquire_listing: 'Inquire - Listing',
  virtual_tour: 'Virtual Tour',
  recommended: 'Recommended',
  modal: 'Modal',
  account: 'Account',
  listings: 'Listings',
  space_calculator: 'Space Calculator',
  homepage: 'Homepage',
  general_inquiry: 'General Inquiry',
  search: 'Search',
  suggested_listings: 'Suggested Listings',
  user_change_location: 'User change location',
  location_autoset: 'Location automatically set',
  public_surveys: 'Public Surveys',
  logged_in_surveys: 'Renter Logged-In Survey',
  location_selector: 'Location Selector',
};

export const eventActions = {
  skip: 'Skip',
};

// Stub queue analytics actions while the API loads asynchronously
const queuedEvents = [];
let timer;
function queuedTrack() {
  if (window.analytics) {
    // Analytics has loaded, clear the queue
    queuedEvents.forEach(([action, name, args]) =>
      window.analytics[action](name, args)
    );
    window.clearTimeout(timer);
  } else {
    // Try again later
    timer = window.setTimeout(queuedTrack, 100);
  }
}

function addDefaultArguments(args = {}) {
  return {
    ...args,
    server_id: getCookie('SERVERID'),
  };
}

/**
 * This fires the [Segment track function] {@link https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#track}
 * @param {string} event - An event enum from analyticsEvents
 * @param {Object} args - Object usually containing eventCategories
 * @param {string?} action - A Segment analytics action defaulted to "track"
 */
export function track(event, args, action = 'track') {
  if (typeof window === 'undefined') return;
  const argsWithDefaults = addDefaultArguments(args);
  if (window.analytics) {
    window.analytics[action](event, argsWithDefaults);
  } else {
    queuedEvents.push([action, event, argsWithDefaults]);
    queuedTrack();
  }
}

export const trackPageView = (args) => {
  if (typeof window !== 'undefined' && window.analytics)
    window.analytics.page(addDefaultArguments(args));
};

export const trackPPCSignUp = () => {
  window.google_conversion_id = 998580626;
  window.google_conversion_label = 'GG71CIa_nQkQksOU3AM';
  window.google_remarketing_only = false;
  window.google_conversion_format = '3';
  if (window.google_trackConversion) window.google_trackConversion();
};

export function identify(id, args) {
  track(id, args, 'identify');
  track(analyticsEvents.login, { userId: id });
}
