import React, { PureComponent } from 'react';

import styles from '../style.module.scss';

export default class LowerFooter extends PureComponent {
  year = new Date().getFullYear();

  render() {
    return (
      <div className={styles.lower}>
        <div className={`inset ${styles.responsiveFlex}`}>
          <div className={styles.newYorkTopCities}>
            <nav className={`${styles.section} ${styles.officeSpace}`}>
              <a href="/office-space/ny/new-york" className={styles.buildings}>
                <h2>Office Space in NYC</h2>
              </a>
              <div className={styles.submarkets}>
                <h2 className={styles.locationSubHeading}>
                  <a
                    href="/office-space/ny/new-york/manhattan"
                    className={styles.buildings}
                  >
                    Manhattan
                  </a>
                </h2>
                <h3 className={styles.locationSubHeading}>
                  <a
                    href="/office-space/ny/new-york/brooklyn"
                    className={styles.buildings}
                  >
                    Brooklyn
                  </a>
                </h3>
                <h3 className={styles.locationSubHeading}>
                  <a
                    href="/office-space/ny/new-york/queens"
                    className={styles.buildings}
                  >
                    Queens
                  </a>
                </h3>
              </div>
              <div className={styles.neighborhoodList}>
                <ul>
                  <li>
                    <a href="/office-space/ny/new-york/chelsea">Chelsea</a>
                  </li>
                  <li>
                    <a href="/office-space/ny/new-york/chinatown">Chinatown</a>
                  </li>
                  <li>
                    <a href="/office-space/ny/new-york/east-village">
                      East Village
                    </a>
                  </li>
                  <li>
                    <a href="/office-space/ny/new-york/financial-district">
                      Financial District
                    </a>
                  </li>
                  <li>
                    <a href="/office-space/ny/new-york/flatiron-district">
                      Flatiron District
                    </a>
                  </li>
                  <li>
                    <a href="/office-space/ny/new-york/garment-district">
                      Garment District
                    </a>
                  </li>
                  <li>
                    <a href="/office-space/ny/new-york/greenwich-village">
                      Greenwich Village
                    </a>
                  </li>
                  <li>
                    <a href="/office-space/ny/new-york/harlem">Harlem</a>
                  </li>
                  <li>
                    <a href="/office-space/ny/new-york/hudson-square">
                      Hudson Square
                    </a>
                  </li>
                  <li>
                    <a href="/office-space/ny/new-york/little-italy">
                      Little Italy
                    </a>
                  </li>
                  <li>
                    <a href="/office-space/ny/new-york/lower-east-side">
                      Lower East Side
                    </a>
                  </li>
                  <li>
                    <a href="/office-space/ny/new-york/midtown">Midtown</a>
                  </li>
                  <li>
                    <a href="/office-space/ny/new-york/noho">NoHo</a>
                  </li>
                  <li>
                    <a href="/office-space/m/ny/new-york/soho/94e3c05e-07b1-4ec2-9ce6-52de63e49ab0">
                      Soho
                    </a>
                  </li>
                  <li>
                    <a href="/office-space/ny/new-york/tribeca">Tribeca</a>
                  </li>
                  <li>
                    <a href="/office-space/ny/new-york/union-square">
                      Union Square
                    </a>
                  </li>
                  <li>
                    <a href="/office-space/ny/new-york/upper-east-side">
                      Upper East Side
                    </a>
                  </li>
                  <li>
                    <a href="/office-space/ny/new-york/upper-west-side">
                      Upper West Side
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
            <nav className={`${styles.section} ${styles.officeSpace}`}>
              <a href="/office-space/tx/houston" className={styles.buildings}>
                <h2>Office Space in Houston</h2>
              </a>
              <div className={styles.submarkets}>
                <h2 className={styles.locationSubHeading}>
                  <a
                    href="/office-space/tx/houston/downtown"
                    className={styles.buildings}
                  >
                    Downtown
                  </a>
                </h2>
              </div>
              <div className={styles.neighborhoodList}>
                <ul>
                  <li>
                    <a href="/office-space/tx/houston/eado">EaDo</a>
                  </li>
                  <li>
                    <a href="/office-space/tx/houston/energy-corridor">
                      Energy Corridor
                    </a>
                  </li>
                  <li>
                    <a href="/office-space/tx/houston/greenspoint">
                      Greenspoint
                    </a>
                  </li>
                  <li>
                    <a href="/office-space/tx/houston/greenway-plaza">
                      Greenway Plaza
                    </a>
                  </li>
                  <li>
                    <a href="/office-space/tx/houston/houston-heights">
                      Houston Heights
                    </a>
                  </li>
                  <li>
                    <a href="/office-space/tx/houston/memorial">Memorial</a>
                  </li>
                  <li>
                    <a href="/office-space/tx/houston/midtown">Midtown</a>
                  </li>
                  <li>
                    <a href="/office-space/tx/houston/montrose">Montrose</a>
                  </li>
                  <li>
                    <a href="/office-space/tx/houston/southwest-houston">
                      Southwest Houston
                    </a>
                  </li>
                  <li>
                    <a href="/office-space/tx/houston/spring">Spring</a>
                  </li>
                  <li>
                    <a href="/office-space/tx/houston/spring-branch">
                      Spring Branch
                    </a>
                  </li>
                  <li>
                    <a href="/office-space/tx/houston/sugar-land">Sugar Land</a>
                  </li>
                  <li>
                    <a href="/office-space/tx/houston/medical-center">
                      Medical Center
                    </a>
                  </li>
                  <li>
                    <a href="/office-space/tx/houston/uptown">Uptown</a>
                  </li>
                  <li>
                    <a href="/office-space/tx/houston/west-university">
                      West University
                    </a>
                  </li>
                  <li>
                    <a href="/office-space/tx/houston/westchase">Westchase</a>
                  </li>
                </ul>
              </div>
            </nav>
            <nav className={`${styles.section} ${styles.footerBottomWrapper}`}>
              <div className={styles.footerBottomCities}>
                <h2>Top Cities</h2>
                <div className={styles.submarkets}>
                  <h3 className={styles.locationSubHeading}>
                    <a href="/buildings/">All Buildings</a>
                  </h3>
                </div>
                <div className={styles.neighborhoodList}>
                  <ul>
                    <li>
                      <a href="/office-space/ga/atlanta">Atlanta</a>
                    </li>
                    <li>
                      <a href="/office-space/tx/austin">Austin</a>
                    </li>
                    <li>
                      <a href="/office-space/ma/boston">Boston</a>
                    </li>
                    <li>
                      <a href="/office-space/nc/charlotte">Charlotte</a>
                    </li>
                    <li>
                      <a href="/office-space/il/chicago">Chicago</a>
                    </li>
                    <li>
                      <a href="/office-space/tx/dallas">Dallas</a>
                    </li>
                    <li>
                      <a href="/office-space/co/denver">Denver</a>
                    </li>
                    <li>
                      <a href="/office-space/fl/fort-lauderdale">
                        Fort Lauderdale
                      </a>
                    </li>
                    <li>
                      <a href="/office-space/ca/los-angeles">Los Angeles</a>
                    </li>
                    <li>
                      <a href="/office-space/fl/miami">Miami</a>
                    </li>
                    <li>
                      <a href="/office-space/mn/minneapolis">Minneapolis</a>
                    </li>
                    <li>
                      <a href="/office-space/tn/nashville">Nashville</a>
                    </li>
                    <li>
                      <a href="/office-space/fl/palm-beach">Palm Beach</a>
                    </li>
                    <li>
                      <a href="/office-space/pa/philadelphia">Philadelphia</a>
                    </li>
                    <li>
                      <a href="/office-space/pa/pittsburgh">Pittsburgh</a>
                    </li>
                    <li>
                      <a href="/office-space/ca/san-diego">San Diego</a>
                    </li>
                    <li>
                      <a href="/office-space/ca/san-francisco">San Francisco</a>
                    </li>
                    <li>
                      <a href="/office-space/dc/washington">Washington, D.C.</a>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
            <footer className={styles.footerBottomLegal}>
              <span className={styles.copyright}>
                &copy; {this.year} SquareFoot
              </span>{' '}
              <span className={styles.allRightsReserved}>
                All rights reserved
              </span>
              <div className={styles.legalLinks}>
                <a className={styles.legalLink} href="/terms">
                  Terms
                </a>
                <a className={styles.legalLink} href="/privacy">
                  Privacy
                </a>
              </div>
            </footer>
          </div>
        </div>
      </div>
    );
  }
}
