import React from 'react';
import { Link } from 'react-router-dom';
import H1 from 'components/deprecated/legacyElements/H1';

export default function UnauthorizedPage() {
  return (
    <div className="inset">
      <H1 inset>Unauthorized</H1>
      <p>
        You are not permitted to access this content.
        <br />
        <Link to="/">Home &rarr;</Link>
      </p>
    </div>
  );
}
