import { identify } from 'util/analytics';

const initialState = {
  currentUser: null,
  numberOfInteractions: 0,
};

export function login(payload) {
  if (payload) {
    const { uuid, email, displayName } = payload;
    identify(uuid, { email, name: displayName });
  }
  return { type: 'USER_LOGIN_CHANGED', payload };
}
export const logout = () => login();

export const updateUser = (payload) => ({ type: 'USER_UPDATED', payload });
export const updateDisplayName = (payload) => updateUser(payload);
export const updateVisitorProfile = (payload) => ({
  type: 'VISITOR_PROFILE_UPDATED',
  payload,
});

export default function reducer(state = initialState, action) {
  if (action.type === 'USER_LOGIN_CHANGED') {
    return {
      currentUser: action.payload,
      numberOfInteractions: state.numberOfInteractions,
    };
  }
  if (action.type === 'USER_UPDATED') {
    return {
      currentUser: { ...state.currentUser, ...action.payload },
      numberOfInteractions: state.numberOfInteractions,
    };
  }
  if (action.type === 'VISITOR_PROFILE_UPDATED') {
    return {
      currentUser: state.currentUser,
      numberOfInteractions:
        action.payload.counterOperation === 'INCREMENT'
          ? state.numberOfInteractions + 1
          : action.payload.numberOfInteractions,
    };
  }
  return state;
}
